import "./styles.sass";

// MAIN NEWS COMPONENT
const MainNews = ({image, heading, text, date}) => {

    // REACT INLINE STYLES
    const style = {
        background: `linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.75) 100%), url(${image}), lightgray 50%`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      };

    // LAYOUT
    return (
        <div className="main-news" style={style}>
            <div className="flex flex_space-between main-news__info">
                <div className="flex flex_column">
                    <h2 className="main-news__info__heading">{heading}</h2>
                    <p className="main-news__info__text">{text}</p>
                </div>
                <div className="main-news__info__date">
                    <p className="main-news__info__date__text">{date}</p>
                </div>
            </div>
        </div>
    );
};

// EXPORT
export default MainNews;