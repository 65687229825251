import React, {useState} from "react";
import langEn from "../../../assets/images/engLanguage.svg";
import langRu from "../../../assets/images/ruLanguage.svg";
import langUz from "../../../assets/images/uzbLanguage.svg";
import "./styles.sass";
import { useDispatch, useSelector } from "react-redux";
import { switchLanguageActionCreator } from "../../../redux/language/actions";

export var language;

const Languages = () => {

    // USE DISPATCH
    const dispatch = useDispatch();

    // USE SELECTOR
    const languageState = useSelector(state => state.language);

    const [lang, setLang] = useState(false);

    const Languages = [
        {
            key: "en-EN",
            label: "English",
            icon: langEn,
        },
        {
            key: "ru-RU",
            label: "Русский",
            icon: langRu,
        },
        {
            key: "uz-UZ",
            label: "O`zbekcha",
            icon: langUz,
        },
    ];

    const handleLang = () => {
        setLang(!lang);
    };

    const handleLanguageChange = (language) => () => {
        dispatch(switchLanguageActionCreator(language));
        setLang(!lang);
    };


    document.body.addEventListener("click", (e) => {
        if (e.target?.className != "switcher_handler" && lang)
            handleLang();
    });
  return (
        <div className="component_language">
            <div className="language_container flex flex_space-between align_items-center ">
                <div className="switcher_handler" onClick={handleLang}></div>
                <div className="language_bar flex align_items-center">
                   <i className="icon-Group"></i>
                   <p className="language_text">
                    {Languages.find(language => language.key.slice(0, 2) === languageState.currentLanguage).label}
                   </p>
                </div>
                {lang ? (
                    <div className="languages-switcher_items">
                        {Languages.map((language) => (
                            <div className="switcher_bar" key={language.key}>
                                {languageState.currentLanguage !== language.key.slice(0, 2) && (
                                    <div
                                        key={language.key}
                                        className="language_bar flex align_items-center"
                                        onClick={handleLanguageChange(language.key.slice(0, 2))}
                                        data-language={language.key}
                                    >
                                        <img
                                            src={language.icon}
                                            alt={language.label}
                                        />
                                        <p className="languages_name">
                                            {language.label}
                                        </p>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default Languages;
