import Location from "../../components/UI/Location";
import tashkent from "../../assets/images/tashkent.png";
import tashkent2 from "../../assets/images/tashkent2.png";
import samarkand from "../../assets/images/samarkand.png";
import andijan from "../../assets/images/andijan.png";
import jizzak from "../../assets/images/jizzak.png";
import bukhara from "../../assets/images/bukhara.png";
import navoi from "../../assets/images/navoi.png";
import axangaran from "../../assets/images/axangaran.png";
import Step from "../../components/UI/Step";
import Form from "../../components/Functional/Form";
import "./styles.sass";
import { useSelector } from "react-redux";
import { translate } from "../../translations/translate";

// CONTACTS PAGE
const Contacts = () => {

    // LANGUAGE
    const language = useSelector(state => state.language.currentLanguage);
    const __ = (key) => translate(language, key);

    // LAYOUT
    return (
        <div className="contacts flex flex_column">
            <div className="main-container">
            <h2 className="contacts__heading">{__("Our")} 
            <span className="contacts__heading_colored"> {__("contacts")}</span> {__("and")} 
            <span className="contacts__heading_colored"> {__("locations")}</span></h2>
            <div className="contacts__items flex flex_content-center">
                <Location
                  image={tashkent}
                  city="Tashkent"
                  startTime="9:00"
                  endTime="18:00"
                  phoneNumber="+998 71 200 01 73"
                  address="Almazar region, st. Yangi Almazar, 51"
                />
                <Location
                  image={tashkent2}
                  city="Tashkent"
                  startTime="9:00"
                  endTime="18:00"
                  phoneNumber="+998 71 200 01 73"
                  address="Yunusabad region, st. Dekhkanabad"
                />
                <Location 
                    image={samarkand}
                    city="Samarkand"
                    startTime="9:00"
                    endTime="18:00"
                    phoneNumber="+998 94 476-55-71"
                    address="st. Makhmud Koshgariy, house 49"
                />
                <Location 
                    image={andijan}
                    city="Andijan"
                    startTime="9:00"
                    endTime="18:00"
                    phoneNumber="+998 99 320-33-03"
                    address="Asaka region, st. Karvonsaroy, house 1"
                />
                <Location 
                    image={jizzak}
                    city="Jizzakh"
                    startTime="9:00"
                    endTime="18:00"
                    phoneNumber="+998 33 330-20-03"
                    address="st. Shifokorlar, house 0"
                />
                <Location 
                    image={bukhara}
                    city="Bukhara"
                    startTime="9:00"
                    endTime="18:00"
                    phoneNumber="+998 98 774-44-99"
                    address="st. Khilol Bukhariy, house 84"
                />
                <Location 
                    image={navoi}
                    city="Navoi"
                    startTime="9:00"
                    endTime="18:00"
                    phoneNumber="+998 94 476-55-71"
                    address="st. Shifokorlar, house 0"
                />
                <Location 
                    image={axangaran}
                    city="Axangaran"
                    startTime="9:00"
                    endTime="18:00"
                    phoneNumber="+998 94 476-55-71"
                    address="2nd microdistrict, 11a, 37"
                />
            </div>
            </div>
            <div className="steps" id="steps">
                <div className="main-container">
                    <div className="steps-container">
                        <div className="steps_title">
                            <h3 className="title"> <span className="title_primary"> {__("Steps")} </span> {__("for receiving our product")} </h3>
                        </div>
                        <div className="steps-block flex flex_space-between">
                            <div className="step_items">
                                <Step stepNumber="1" heading="Sign up in Admin Panel" text="Begin by signing up on the admin panel. This will grant you access to the suite of tools and features essential for leveraging our product. Simply provide your details, choose a secure password, and you'll be on your way to experiencing everything our product has to offer."/>
                                <Step stepNumber="2" heading="Incorporate a Business" text="Next, incorporate your business right within our platform. By adding your company details, you establish your business's presence and unlock specific functionalities tailored for enterprises."/>
                                <Step stepNumber="3" heading="Receive Fiscal Module" text="Once your business is incorporated, you'll receive a fiscal module. This integral component ensures seamless financial operations and compliance within our platform. It's designed to assist with tax calculations, financial reporting, and other fiscal responsibilities, making your business operations smooth and efficient."/>
                                <Step stepNumber="4" heading="Receive a Cashdesk" text="After setting up the fiscal module, you'll be provided with a cashdesk. This essential tool facilitates all your transactional needs, from processing payments to handling receipts. Tailored for businesses of all sizes, our cashdesk ensures a smooth, secure, and efficient point-of-sale experience, allowing you to serve your customers with confidence and ease."/>
                            </div>
                            <div className="form flex">
                                <Form/>
                            </div>
                        </div>
                        
                        
                    </div>
                    
                </div>
            </div>
        </div>
    );
};

// EXPORTING PAGE
export default Contacts;