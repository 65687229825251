import { useSelector } from "react-redux";
import "./styles.sass";
import { translate } from "../../../translations/translate";

// BUSINESS VISION COMPONENT
const BusinessVision = ({heading, text, image}) => {

    // LANGUAGE
    const language = useSelector(state => state.language.currentLanguage);
    const __ = (key) => translate(language, key);

    // LAYOUT
    return (
        <div className="shadow-box shadow-box_business-vision">
            <div className="shadow-box__image">
                <img src={image} alt="img" className="image" />
            </div>
            
            <div className="shadow-box__text">
                <div className="business-heading">
                    <h1 className="title">{__(heading)}</h1>
                </div>
                <div className="business-text">
                    <p className="text">{__(text)}</p>
                </div>
            </div>
        </div>  
    );
};

// EXPORTING COMPONENT
export default BusinessVision;