import { useSelector } from "react-redux";
import "./styles.sass";
import { translate } from "../../../translations/translate";

// BUTTON COMPONENT
const Button = ({className, text, onClick, type, icon, isDisabled}) => {

    // LANGUAGE
    const language = useSelector(state => state.language.currentLanguage);
    const __ = (key) => translate(language, key);
    // LAYOUT
    return (
        <button className={`btn ${className}`} type={type} onClick={onClick} disabled={isDisabled}>
            {__(text) || ""}
            { icon && <i className={`icon ${icon}`}></i> }
        </button>
    );
};

// EXPORTING COMPONENT
export default Button;