import MainNews from "../../components/UI/MainNews";
import NewsItem from "../../components/UI/NewsItem";
import shopImage from "../../assets/images/main_news_shop.jpg";
import newsImage from "../../assets/images/newsItem.png";
import Pagination from "../../components/Functional/Pagination";
import "./styles.sass";

// NEWS PAGE
const News = () => {

    // LAYOUT 
    return (
        <div className="news flex flex_column">
            <MainNews
                image={shopImage}
                heading="News About SME"
                text="There will be given some news in our blog posts, so users will be able to read and observe more knowledges."
                date="20 minutes ago"
            />
            <div className="flex news__news-items">
                {
                    Array.from({ length: 9 }).map((_, index) => (
                        <NewsItem 
                            key={index} 
                            image={newsImage}
                            heading="News About SME"
                            text="There will be given some news in our blog posts, so users will be able to read and observe more knowledges."
                            date="20 minutes ago"
                        />
                    ))
                }
            </div>
            <Pagination 
                currentPage={1}
                numberOfPages={10}
            />
        </div>
    );
};

// EXPORTING PAGE
export default News;