// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.shadow-box_business-partner {
  box-shadow: 0px 4px 25px 0px rgba(38, 84, 93, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 200px;
  height: 100px;
}
.shadow-box_business-partner .shadow-box_business-partner__logo {
  max-width: 130px;
}

.shadow-box_white {
  background-color: #ffffff;
}

.partners-box {
  position: relative;
  width: 200px;
  height: 100px;
  margin-top: 36px;
}
.partners-box .partner-box_flip {
  position: absolute;
  width: 100%;
  backface-visibility: hidden;
  transition: all 1.5s ease;
  cursor: pointer;
}
.partners-box .partner-box_flip:nth-child(2) {
  transform: rotateY(180deg);
}
.partners-box .partner-box_no-flip {
  position: absolute;
  width: 100%;
  backface-visibility: hidden;
  transition: all 1.5s ease;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/UI/Partner/styles.sass"],"names":[],"mappings":"AAEA;EACI,mDAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,gBAAA;EACA,aAAA;AADJ;AAEI;EACI,gBAAA;AAAR;;AACA;EACI,yBAAA;AAEJ;;AAAA;EACI,kBAAA;EACA,YAAA;EACA,aAAA;EACA,gBAAA;AAGJ;AAFI;EACI,kBAAA;EACA,WAAA;EACA,2BAAA;EACA,yBAAA;EACA,eAAA;AAIR;AAHQ;EACI,0BAAA;AAKZ;AAJI;EACI,kBAAA;EACA,WAAA;EACA,2BAAA;EACA,yBAAA;EACA,eAAA;AAMR","sourcesContent":["@import ../../../assets/styles/basic/_variables\n    \n.shadow-box_business-partner\n    box-shadow: map-get($cardBoxShadow, \"shadow\")\n    display: flex\n    justify-content: center\n    align-items: center\n    max-width: 200px\n    height: 100px\n    .shadow-box_business-partner__logo\n        max-width: 130px\n.shadow-box_white\n    background-color: map-get($basic, \"white\")\n\n.partners-box\n    position: relative\n    width: 200px\n    height: 100px\n    margin-top: 36px\n    .partner-box_flip\n        position: absolute\n        width: 100%\n        backface-visibility: hidden\n        transition: all 1.5s ease\n        cursor: pointer\n        &:nth-child(2)\n            transform: rotateY(180deg)\n    .partner-box_no-flip\n        position: absolute\n        width: 100%\n        backface-visibility: hidden\n        transition: all 1.5s ease\n        cursor: pointer\n    //.flipped\n    //    &:nth-child(2)\n    //        transform: rotateY(0deg)\n    //    &:nth-child(1)\n    //        transform: rotateY(-180deg)\n    //&:hover\n    //    .partner-box_flip\n    //        &:nth-child(2)\n    //            transform: rotateY(0deg)\n    //        &:nth-child(1)\n    //            transform: rotateY(-180deg)\n            \n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
