import "./styles.sass";

// PRODUCT COMPONENT
const Product = ({image, productName, description}) => {
    
    // LAYOUT
    return (
        <div className="shadow-box shadow-box_product _bra-24">
            <img src={image} alt="product" className="shadow-box_product__image" />
            <h2 className="shadow-box_product__heading">{productName}</h2>
            <p className="shadow-box_product__text_light">{description}</p>
        </div>
    );
};

// EXPORTING COMPONENT
export default Product;