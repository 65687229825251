import "./styles.sass";

const PrivacyPolicy = () => {
  return (
    <div className="page-privacy">
      <h1>Privacy Policy of E-POS Systems</h1>
      <p>
        Thank you for choosing E-POS Systems! This Privacy Policy is designed to inform you about the information we
        collect, why we collect it, and how we use it.
      </p>

      <h2>Information We Collect</h2>

      <h3>1. Personal Information</h3>
      <p>To provide you with the best experience, we collect the following personal information when you register for
        our app:</p>

      <ul>
        <li>
          <strong>Phone Number:</strong> Used as your unique identifier for registration and account management.
        </li>
      </ul>

      <h3>2. Usage Information</h3>
      <p>We also collect information about how you interact with our app, such as:</p>

      <ul>
        <li>
          <strong>Device Information:</strong> Including device type, operating system, and unique device identifiers.
        </li>
        <li>
          <strong>Log Information:</strong> Details about your activity on the app, including pages visited, books read, and interactions.
        </li>
      </ul>

      <h2>How We Use Your Information</h2>

      <p>We use the collected information to:</p>

      <ul>
        <li>
          <strong>Provide and Improve Services:</strong> To deliver, maintain, and enhance the functionality of E-POS Cashdesk and E-POS Mobile.
        </li>
        <li>
          <strong>Personalize Content:</strong> To tailor the content and recommendations based on your preferences.
        </li>
        <li>
          <strong>Communicate with You:</strong> Send important updates, notifications, and respond to your inquiries.
        </li>
      </ul>

      <h2>Data Security</h2>
      <p>
        We prioritize the security of your data and implement industry-standard measures to protect your personal information from unauthorized access or disclosure.
      </p>

      <h2>Contact Us</h2>
      <p>
        If you have any questions or concerns about our Privacy Policy, please contact us at <a href="mailto:info@epos.uz">info@epos.uz</a>.
      </p>
      <p>By using E-POS Cashdesk and E-POS Mobile, you agree to the terms outlined in this Privacy Policy.</p>

    </div>
  );
};

export default PrivacyPolicy;
