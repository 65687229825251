// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.shadow-box_review {
  max-width: 380px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}
.shadow-box_review .shadow-box_review__image {
  width: 52px;
  height: 52px;
  border-radius: 40px;
  margin-right: 16px;
}
.shadow-box_review .shadow-box_review__heading {
  color: #1E1E1E;
}
.shadow-box_review .shadow-box_review__text {
  color: #818181;
}
.shadow-box_review .shadow-box_review__description {
  margin-top: 16px;
}

.shadow-box_review:hover {
  box-shadow: 0px 4px 25px 0px rgba(38, 84, 93, 0.25);
}`, "",{"version":3,"sources":["webpack://./src/components/UI/Review/styles.sass"],"names":[],"mappings":"AAEA;EACI,gBAAA;EACA,aAAA;EACA,aAAA;EACA,sBAAA;EACA,aAAA;AADJ;AAEI;EACI,WAAA;EACA,YAAA;EACA,mBAAA;EACA,kBAAA;AAAR;AACI;EACI,cAAA;AACR;AAAI;EACI,cAAA;AAER;AADI;EACI,gBAAA;AAGR;;AAFA;EACI,mDAAA;AAKJ","sourcesContent":["@import ../../../assets/styles/basic/_variables\n    \n.shadow-box_review\n    max-width: 380px\n    padding: 24px\n    display: flex\n    flex-direction: column\n    row-gap: 16px\n    .shadow-box_review__image\n        width: 52px\n        height: 52px\n        border-radius: 40px\n        margin-right: 16px\n    .shadow-box_review__heading\n        color: map-get($default, \"black\")\n    .shadow-box_review__text\n        color: map-get($neutral, \"500\")\n    .shadow-box_review__description\n        margin-top: 16px\n.shadow-box_review:hover\n    box-shadow: map-get($cardBoxShadow, \"shadow\")"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
