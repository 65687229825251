export const RU = {
    Home: "Главная",
    About: "О Нас",
    Contacts: "Контакты",
    "Sign up": "Регистрация",
    "Try Demo": "Попробовать",
    "POS System for": "POS-система для",
    "automation": "автоматизации",
    "work with Taxes Office": "работы с налоговой инспекцией",
    "Our software allows customers to manage their business efficiently, providing accounting for goods, services, payments, sales analysis and more. We also provide the ability to accept electronic payments and work with various payment systems. Our solution helps businesses become more competitive and successful by providing all the tools they need to grow and manage." : "Наше программное обеспечение позволяет клиентам эффективно управлять своим бизнесом, обеспечивая учет товаров, услуг, платежей, анализ продаж и многое другое. Также мы предоставляем возможность принимать электронные платежи и работать с различными платежными системами. Наше решение помогает предприятиям стать более конкурентоспособными и успешными, предоставляя все инструменты, необходимые для роста и управления.",
    "We are": "Мы",
    "working with": "сотрудничаем с",
    "We're": "Мы",
    "the right fit": "подходим",
    "for these businesses": "этим бизнесам",
    "Grocery Stores": "Продуктовые магазины",
    "Furniture Stores": "Мебельные магазины",
    "Electronics Stores": "Магазины электроники",
    "Supplies Stores": "Магазины расходных материалов",
    "Catering Outlets": "Кейтеринговые предприятия",
    "Clothing and Footwear Stores": "Магазины одежды и обуви",
    "Why": "Почему",
    "us?": "мы?",
    "Point of Sale Automation": "Автоматизация торговых точек",
    "When adding goods, the UPSI and category will be filled automatically, based on data from tasnif.soliq.uz.":
    "При добавлении товара УПСИ и категория будут заполнены автоматически, на основании данных с сайта tasnif.soliq.uz.",
    "Admin panel, sales history, inventory data and more!": "Панель администратора, история продаж, данные о запасах и многое другое!",
    "No need to buy an online cash register - the program acts as a virtual cash register.":
    "Не нужно покупать онлайн-кассу – программа выступает в роли виртуальной кассы.",
    "Automatic filling of the UPSU": "Автоматическое заполнение УПСУ",
    "Connected equipment": "Подключенное оборудование",
    "Various types of thermal printers and barcode scanners are supported.": 
    "Поддерживаются различные типы термопринтеров и сканеров штрих-кодов.",
    "Technical support and assistance in work": "Техническая поддержка и помощь в работе",
    "Our specialists are always ready to help with any difficulties and answer any questions related to the program operation!":
    "Наши специалисты всегда готовы помочь с любыми трудностями и ответить на любые вопросы, связанные с работой программы!",
    "Steps": "Шаги",
    "for receiving our product": "для получения нашего продукта",
    "Sign up in Admin Panel": "Зарегистрируйтесь на панели администратора",
    "Begin by signing up on the admin panel. This will grant you access to the suite of tools and features essential for leveraging our product. Simply provide your details, choose a secure password, and you'll be on your way to experiencing everything our product has to offer.":
    "Начните с регистрации в панели администратора. Это предоставит вам доступ к набору инструментов и функций, необходимых для использования нашего продукта. Просто укажите свои данные, выберите надежный пароль, и вы сможете испытать все, что может предложить наш продукт.",
    "Incorporate a Business": "Зарегистрируйте бизнес",
    "Next, incorporate your business right within our platform. By adding your company details, you establish your business's presence and unlock specific functionalities tailored for enterprises.":
    "Затем включите свой бизнес прямо на нашей платформе. Добавляя сведения о своей компании, вы усиливаете присутствие своего бизнеса и открываете специальные функции, специально предназначенные для предприятий.",
    "Receive Fiscal Module": "Получите фискальный модуль",
    "Once your business is incorporated, you'll receive a fiscal module. This integral component ensures seamless financial operations and compliance within our platform. It's designed to assist with tax calculations, financial reporting, and other fiscal responsibilities, making your business operations smooth and efficient.":
    "Как только ваш бизнес будет зарегистрирован, вы получите финансовый модуль. Этот неотъемлемый компонент обеспечивает бесперебойность финансовых операций и соблюдение требований на нашей платформе. Он предназначен для помощи в расчетах налогов, составлении финансовой отчетности и выполнении других финансовых задач, что делает ваши бизнес-операции гладкими и эффективными.",
    "Receive a Cashdesk": "Получите кассу",
    "After setting up the fiscal module, you'll be provided with a cashdesk. This essential tool facilitates all your transactional needs, from processing payments to handling receipts. Tailored for businesses of all sizes, our cashdesk ensures a smooth, secure, and efficient point-of-sale experience, allowing you to serve your customers with confidence and ease.":
    "После настройки фискального модуля вам будет предоставлена ​​касса. Этот важный инструмент облегчает все ваши транзакционные задачи: от обработки платежей до обработки квитанций. Наша касса, адаптированная для предприятий любого размера, обеспечивает бесперебойную, безопасную и эффективную работу в точках продаж, позволяя вам с уверенностью и легкостью обслуживать своих клиентов.",
    "Sections": "Разделы",
    "Headquarter Address": "Адрес штаб-квартиры",
    "Tashkent city": "город Ташкент",
    "Almazar district": "Алмазарский район",
    "Yangi Almazor street": "улица Янги Алмазар",
    "At E-POS Systems, we redefine the way businesses handle transactions. As a leading provider of virtual cashdesks, we harness the power of technology to offer solutions that are not only efficient but also innovative. In an era where digital transformation is paramount, our virtual cashdesks serve as a testament to our commitment to simplifying business operations. We understand the challenges of modern commerce, and our platform is meticulously designed to address them. With our state-of-the-art virtual cashdesks, businesses can ensure seamless, secure, and swift transactions, enhancing customer experience and operational efficiency.":
    "В E-POS Systems мы по-новому определяем способы обработки транзакций. Являясь ведущим поставщиком виртуальных касс, мы используем возможности технологий, чтобы предлагать решения, которые не только эффективны, но и инновационны. В эпоху, когда цифровая трансформация имеет первостепенное значение, наши виртуальные кассы служат свидетельством нашей приверженности упрощению бизнес-операций. Мы понимаем проблемы современной коммерции, и наша платформа тщательно разработана для их решения. Благодаря нашим современным виртуальным кассам предприятия могут обеспечить бесперебойные, безопасные и быстрые транзакции, повышая качество обслуживания клиентов и эффективность работы.",
    "Our company": "Наша компания",
    "in numbers": "в числах",
    "Here you can see our company in terms of statistics and get acquainted with its efficiency.":
    "Здесь вы можете увидеть нашу компанию с точки зрения статистики и ознакомиться с ее эффективностью.",
    "Founded in": "Год основания",
    "Active businesses": "Активные бизнесы",
    "Active cashdesks": "Активные кассы",
    "Business vision": "Бизнес-видение",
    "Our vision is to revolutionize the transactional landscape, making every financial touchpoint seamless, efficient, and forward-thinking. We envision a world where businesses, irrespective of their size or domain, can harness the power of digital innovation, ensuring that every transaction is not just a process, but an experience. Through our state-of-the-art virtual cashdesks, we aim to empower enterprises to operate with unmatched efficiency and to redefine customer interactions, setting a global standard for digital commerce.":
    "Наше видение состоит в том, чтобы произвести революцию в транзакционной среде, сделав каждую финансовую точку взаимодействия гладкой, эффективной и дальновидной. Мы представляем себе мир, в котором предприятия, независимо от их размера или сферы деятельности, смогут использовать возможности цифровых инноваций, гарантируя, что каждая транзакция станет не просто процессом, а опытом. С помощью наших современных виртуальных касс мы стремимся дать предприятиям возможность работать с непревзойденной эффективностью и по-новому определить взаимодействие с клиентами, устанавливая глобальный стандарт цифровой коммерции.",
    "Our": "Наши",
    "contacts": "контакты",
    "and": "и",
    "locations": "локации",
    "Tashkent": "Ташкент",
    "Almazar region, st. Yangi Almazar, 51": "Алмазарский район, ул. Янги Алмазар, 51",
    "Yunusabad region, st. Dekhkanabad": "Юнусабадский район, улица Дехконобод",
    "Samarkand": "Самарканд",
    "st. Makhmud Koshgariy, house 49": "Улица Махмуда Кошгарий, дом 49",
    "Andijan": "Андижан",
    "Asaka region, st. Karvonsaroy, house 1": "Район Асака, улица Карвонсарой, дом 1",
    "Jizzakh": "Джиззах",
    "st. Shifokorlar, house 0": "Улица Шифокорлар, дом 0",
    "Bukhara": "Бухара",
    "st. Khilol Bukhariy, house 84": "Улица Хилол Бухорий дом 84",
    "Navoi": "Навои",
    "Axangaran": "Ахангаран",
    "2nd microdistrict, 11a, 37": "2 микрорайон, 11а, 37",
    // shop description translate
    "The E-POS Shop program is designed for retail outlets (wholesale and retail), can also be suitable for cafes, restaurants, points of sale of household appliances and many others. The advantage of the program is direct integration with the tax committee and the possibility of instant fiscalization of payments. To get started, you will need to purchase software and get a fiscal module from the Tax Committee. For the program to work, you only need a personal computer (or a cash register monoblock), a 2D scanner and a printer for printing receipts.": "Программа E-POS Shop предназначена для торговых точек (оптовых и розничных), может подойти так же кафе, ресторанам, точкам продажи бытовой техники и многим другим. Преимуществом программы является прямая интеграция с налоговым комитетом и возможность моментальной фискализации платежей. Для начала работы вам потребуется приобрести программное обеспечие и получить фискальный модуль от Налогового Комитета. Для работы программы вам потребуется лишь персональный компьютер (либо кассовый моноблок), 2Д сканер и принтер для печати чеков.",
    "Initial connection": "Первоначальное подключение",
    "Monthly subscription fee": "Месячная абонентская плата",
    "Name" : "Имя",
    "Phone number" : "Номер телефона",
    "Message" : "Сообщение",
    "I am informed about the privacy policy and consent to the processing of my personal data." : "Я прочитал политику конфиденциальности и согласен на обработку моих персональных данных.",
    "Our Product" : "Наш продукт",
    soums: "сумов",
    "In development": "В разработке",
    "E-POS Systems 2023 All rights reserved." : "E-POS Systems 2023. Все права защищены.",
    "Submit" : "Отправить",
    "LLC" : "ООО",

    // cashdesk description translate
    "The E-POS Cashdesk program is designed for business automation. The program has a nice design, flexible configuration and fast operation. The program is paired with the E-POS Management control panel. It offers business management, viewing sales statistics and employee management.": "Программа E-POS Cashdesk предназначена для автоматизации бизнеса. Программа имеет приятный дизайн, гибкую настройку и быструю работу. Программа работает в паре с панелью управления E-POS Management. Предлагает в себе управление бизнесом, просмотр статистики продаж и управление сотрудниками.",
    // managment description translate
    "The E-POS Management Dashboard is a website dashboard for the E-POS Cashdesk product. It is a panel for managing business, employees, viewing sales data and maintaining statistics.": "Панель управления E-POS Management является веб-сайт панелью для управления для продукта E-POS Cashdesk. Является панелью для управдения бизнесом, сотрудниками, просмотра данных по продажам и ведения статистики.",
    "Our Product": "Наши продукты",
};