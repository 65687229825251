import "./styles.sass";

// NEWS ITEM COMPONENT
const NewsItem = ({ image, heading, text, date, className }) => {

    // LAYOUT
    return (
        <div className={`flex flex_column shadow-box shadow-box_news-item _bra-24 ${className}`} >
            <div className="image-container">
                <img src={image} alt="news" className="shadow-box_news-item__image" />
            </div>
            <h2 className="shadow-box_news-item__heading">{heading}</h2>
            <p className="shadow-box_news-item__text_light">{text}</p>
            <div className="shadow-box_news-item__date">
                <p className="shadow-box_news-item__date__text">{date}</p>
            </div>
        </div>
    );
};

// EXPORTING COMPONENT
export default NewsItem;