// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-news {
  max-width: 1200px;
  width: 100%;
  height: 578px;
  border-radius: 32px;
  padding: 0 52px 0 52px;
  position: relative;
}
.main-news .main-news__info {
  align-items: flex-start;
  width: 90%;
  position: absolute;
  bottom: 52px;
}
.main-news .main-news__info .main-news__info__heading {
  color: #ffffff;
}
.main-news .main-news__info .main-news__info__text {
  color: #ffffff;
}
.main-news .main-news__info .main-news__info__date {
  max-width: 146px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 4px 16px;
  background-color: #EDF8FA;
  border-radius: 50px;
}
.main-news .main-news__info .main-news__info__date .main-news__info__date__text {
  color: #388392;
}

@media (max-width: 1440px) {
  .main-news {
    align-self: center;
    width: 100%;
    max-width: 920px;
  }
  .main-news .main-news__info__date {
    visibility: hidden;
  }
}
@media (max-width: 670px) {
  .main-news {
    padding: 0 24px;
    width: 328px;
  }
  .main-news .main-news__info {
    flex-direction: column;
  }
  .main-news .main-news__info .main-news__info__date {
    visibility: visible;
    margin-top: 16px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/UI/MainNews/styles.sass"],"names":[],"mappings":"AAEA;EACI,iBAAA;EACA,WAAA;EACA,aAAA;EACA,mBAAA;EACA,sBAAA;EACA,kBAAA;AADJ;AAEI;EACI,uBAAA;EACA,UAAA;EACA,kBAAA;EACA,YAAA;AAAR;AACQ;EACI,cAAA;AACZ;AAAQ;EACI,cAAA;AAEZ;AADQ;EACI,gBAAA;EACA,oBAAA;EACA,uBAAA;EACA,mBAAA;EACA,iBAAA;EACA,yBAAA;EACA,mBAAA;AAGZ;AAFY;EACI,cAAA;AAIhB;;AAFA;EACI;IACI,kBAAA;IACA,WAAA;IACA,gBAAA;EAKN;EAJM;IACI,kBAAA;EAMV;AACF;AALA;EACI;IACI,eAAA;IACA,YAAA;EAON;EANM;IACI,sBAAA;EAQV;EAPU;IACI,mBAAA;IACA,gBAAA;EASd;AACF","sourcesContent":["@import ../../../assets/styles/basic/_variables\n\n.main-news\n    max-width: 1200px\n    width: 100%\n    height: 578px\n    border-radius: 32px\n    padding: 0 52px 0 52px\n    position: relative\n    .main-news__info\n        align-items: flex-start\n        width: 90%\n        position: absolute\n        bottom: 52px\n        .main-news__info__heading\n            color: map-get($basic, \"white\")\n        .main-news__info__text\n            color: map-get($basic, \"white\")\n        .main-news__info__date\n            max-width: 146px\n            display: inline-flex\n            justify-content: center\n            align-items: center\n            padding: 4px 16px\n            background-color: map-get($primary, \"50\")\n            border-radius: 50px\n            .main-news__info__date__text\n                color: map-get($primary, \"500\")\n\n@media (max-width: 1440px)\n    .main-news\n        align-self: center\n        width: 100%\n        max-width: 920px\n        .main-news__info__date\n            visibility: hidden\n\n@media (max-width: 670px)\n    .main-news\n        padding: 0 24px\n        width: 328px\n        .main-news__info\n            flex-direction: column\n            .main-news__info__date\n                visibility: visible\n                margin-top: 16px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
