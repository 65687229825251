import React from 'react';
import Button from "../../components/UI/Button/index";
import Checkbox from '../../components/UI/Checkbox';
import Header from '../../components/UI/Header';
import "./styles.sass";


const Dev = () => {
    return (
        <div className='dev'>
            <div className="block">
                <h1>Buttons</h1>
                <div className="content">
                    <div className="item">
                        <p className="label">Default button</p>
                        <p className="hint">".btn"</p>
                        <Button text="Button" />
                    </div>
                    <div className="item">
                        <p className="label">Button with md class</p>
                         <p className="hint">".btn_md"</p>
                        <Button text="Button MD" className="btn_md"/>
                    </div>
                    <div className="item">
                        <p className="label">Button with md class</p>
                         <p className="hint">".btn_lg"</p>
                        <Button text="Button LG" className="btn_lg"/>
                    </div>
                    <div className="item">
                        <p className="label">Outlined button with sm class</p>
                         <p className="hint">".btn_outline .btn_sm"</p>
                        <Button text="Button Outline SM" className="btn_outline btn_sm"/>
                    </div>
                    <div className="item">
                        <p className="label">Outlined button with md class</p>
                         <p className="hint">".btn_outline .btn_md"</p>
                        <Button text="Button Outline MD" className="btn_outline btn_md"/>
                    </div>
                    <div className="item">
                        <p className="label">Outlined button with lg class</p>
                         <p className="hint">".btn_outline .btn_lg"</p>
                        <Button text="Button Outline LG" className="btn_outline btn_lg"/>
                    </div>
                    <div className="item">
                        <p className="label">Default button with md class and icon inside</p>
                         <p className="hint">".btn_md", pass icon className as "icon" props</p>
                        <Button text="Button Outline MD Icon" className="btn_md" icon="icon-solar_alt-arrow-left-outlie"/>
                    </div>
                </div>
            </div>
            <div className="block">
                <h1>CheckBox</h1>
                <div className="content">
                    <div className="item">
                        <p className="label">Checkbox</p>
                        <p className="hint">"Default CheckBox, from "components/UI/CheckBox" "</p>
                        <Checkbox id="checkbox" htmlFor="checkbox" text="Checkbox"/>
                       
                    </div>
                    <div className="item">
                        <p className="label">Checkbox checked</p>
                        <p className="hint">"Checked"</p>
                        <Checkbox isChecked={true} id="checked" htmlFor="checked" text="Long text for checkbox"/>
                    </div>
                </div>
            </div>
        </div>
    );
};



export default Dev;
