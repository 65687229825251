// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contacts {
  row-gap: 24px;
}
.contacts .contacts__heading {
  font-size: 42px;
  color: #1E1E1E;
}
@media screen and (max-width: 400px) {
  .contacts .contacts__heading {
    margin-top: 24px;
  }
}
.contacts .contacts__heading .contacts__heading_colored {
  color: #388392;
}
.contacts .contacts__items {
  align-self: center;
  padding: 24px 0;
  column-gap: 30px;
  flex-wrap: wrap;
  gap: 24px;
}

@media (max-width: 1014px) {
  .contacts__heading {
    align-self: center;
  }
  .contacts__items {
    flex-direction: column;
  }
}
@media (max-width: 513px) {
  .contacts__heading {
    text-align: center;
    font-size: 36px;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/Contacts/styles.sass"],"names":[],"mappings":"AAEA;EACI,aAAA;AADJ;AAEI;EAGI,eAAA;EACA,cAAA;AAFR;AADQ;EADJ;IAEQ,gBAAA;EAIV;AACF;AAFQ;EACI,cAAA;AAIZ;AAHI;EACI,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,eAAA;EACA,SAAA;AAKR;;AAHA;EACI;IACI,kBAAA;EAMN;EALE;IACI,sBAAA;EAON;AACF;AAPA;EACI;IACI,kBAAA;IACA,eAAA;EASN;AACF","sourcesContent":["@import ../../assets/styles/basic/_variables\n    \n.contacts\n    row-gap: 24px\n    .contacts__heading\n        @media screen and (max-width: 400px)\n            margin-top: 24px\n        font-size: 42px\n        color: map-get($default, \"black\")\n        .contacts__heading_colored\n            color: map-get($primary, \"500\")\n    .contacts__items\n        align-self: center\n        padding: 24px 0\n        column-gap: 30px\n        flex-wrap: wrap\n        gap: 24px\n\n@media (max-width: 1014px)\n    .contacts__heading\n        align-self: center \n    .contacts__items   \n        flex-direction: column\n@media (max-width: 513px)\n    .contacts__heading\n        text-align: center\n        font-size: 36px"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
