import { Route, Routes } from 'react-router';
import Header from "./components/UI/Header";
import Footer from "./components/UI/Footer";
import Main from "./pages/Main/";
import About from "./pages/About";
import News from "./pages/News";
import NewsInner from "./pages/NewsInner";
import Contacts from "./pages/Contacts";
import Dev from './pages/Dev';
import "./assets/fonts/icons/style.css";
import OurProduct from './pages/OurProduct';
import ScrollToTop from "./components/Functional/ScrollTop/ScrollTop";
import PrivacyPolicy from "./pages/PrivacyPolicy";

const App = () => {

  // ROUTING OF THE WEBSITE
  return (
    <div className="layout flex flex_column">
      <Header />
      {/* <div className="main-container"> */}
      {/*   Для прокрутки вверх*/}
      <ScrollToTop/>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/about" element={<About />} />
          {/* <Route path="/news" element={<News />} /> */}
          {/* <Route path="/news/:id" element={<NewsInner />} /> */}
          {/* <Route path="/news_inner" element={<NewsInner />} /> */}
          <Route path="/contacts" element={<Contacts />} />
          <Route path="/our_product" element={<OurProduct />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        {/* <Route path="dev" element={<Dev/>}/> */}
        </Routes>
      {/* </div> */}
      <Footer />
    </div>
  );
};

export default App;


