import "./styles.sass";

// CHECKBOX COMPONENT
const Checkbox = ({ isChecked, id, htmlFor, text, onClick }) => {

    // LAYOUT
    return (
        <label className="checkbox_label" htmlFor={htmlFor}>
            <input
                onChange={onClick}
                className="checkbox_input"
                id={id}
                name="checkbox"
                type="checkbox"
                checked={isChecked  && "checked"}
            /> 
            <p className="checkbox_text">{text}</p>
            <span className="checkbox"/>
        </label>
        
    );
};

// EXPORTING COMPONENT
export default Checkbox;