import { useSelector } from "react-redux";
import "./styles.sass";
import { translate } from "../../../translations/translate";

// COMPANY IN NUMBERS COMPONENT
const CompanyNumber = ({value, name}) => {

    // LANGUAGE
    const language = useSelector(state => state.language.currentLanguage);
    const __ = (key) => translate(language, key);

    // LAYOUT
    return (
        <div className="shadow-box shadow-box_company-number _bra-12">
            <h1 className=" company-heading text_align-center">{value}</h1>
            <p className="company-subtitle text_align-center">{__(name)}</p>
        </div>
    );
};

// EXPORTING COMPONENT
export default CompanyNumber;