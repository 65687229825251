import { useSelector } from "react-redux";
import "./styles.sass";
import { translate } from "../../../translations/translate";

// WHY US ITEM COMPONENT
const WhyUsItem = ({icon, heading, text}) => {

    // LANGUAGE
    const language = useSelector(state => state.language.currentLanguage);
    const __ = (key) => translate(language, key);

    // LAYOUT
    return (
        <div className="shadow-box shadow-box_why-us-item _bra-24">
            <div className="icon-ball flex align_items-center flex_content-center">
                <i className={`shadow-box_why-us-item__icon ${icon}`} />
            </div>
            <div className="shadow-box_why-us-item__cross"></div>
            <h3 className="shadow-box_why-us-item__heading">{__(heading)}</h3>
            <p className="shadow-box_why-us-item__text">{__(text)}</p>
        </div>
    );
};

// EXPORTING COMPONENT
export default WhyUsItem;