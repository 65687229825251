import "./styles.sass";

// TEAM MEMBER COMPONENT
const TeamMember = ({memberName, position, avatar, className}) => {
    
    // LAYOUT
    return (
        <div className={`shadow-box shadow-box_team-member _bra-24 ${className}`}>
            <img src={avatar} alt="avatar" className="shadow-box_team-member__image" />
            <div className="flex flex_column shadow-box_team-member__block">
                <h3 className="shadow-box_team-member__heading">{memberName}</h3>
                <h4 className="shadow-box_team-member__text">{position}</h4>
            </div>
        </div>
    );
};

// EXPORTING COMPONENT
export default TeamMember;