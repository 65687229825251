import "./styles.sass";

// REVIEW COMPONENT
const Review = ({avatar, userName, business, text, className}) => {
    
    // LAYOUT
    return (
        <div className={`shadow-box shadow-box_review _bra-24 ${className}`}>
            <div className="flex shadow-box_review__person">
                <img src={avatar} alt="avatar" className="shadow-box_review__image" />
                <div className="flex flex_column">
                    <h4 className="shadow-box_review__heading">{userName}</h4>
                    <p className="shadow-box_review__text">{business}</p>
                </div>
            </div>
            <p className="shadow-box_review__description">{text}</p>
        </div>
    );
};

// EXPORTING COMPONENT
export default Review;