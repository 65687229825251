import { useSelector } from "react-redux";
import "./styles.sass";
import { translate } from "../../../translations/translate";

// LOCATION COMPONENT
const Location = ({image, city, startTime, endTime, phoneNumber, address}) => {

    // LANGUAGE
    const language = useSelector(state => state.language.currentLanguage);
    const __ = (key) => translate(language, key);
    
    // LAYOUT
    return (
        <div className="shadow-box shadow-box_location _bra-24">
            <img src={image} alt="city" className="shadow-box_location__image" />
            <div className="flex flex_column">
                <h3 className="shadow-box_location__heading">{__(city)}</h3>
                <div className="flex flex_column shadow-box_location__info">
                    <p className="shadow-box_location__info__text">
                        <i className="icon-solar_alarm-bold shadow-box_location__info__icon" />{`${startTime}-${endTime}`}
                    </p>
                    <p className="shadow-box_location__info__text">
                        <i className="icon-solar_phone-bold shadow-box_location__info__icon" />{phoneNumber}
                    </p>
                    <p className="shadow-box_location__info__text">
                        <i className="icon-solar_map-point-bold shadow-box_location__info__icon" />{__(address)}
                    </p>
                </div>
            </div>
        </div>
    );
};

// EXPORTING COMPONENT
export default Location;