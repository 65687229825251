// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.news {
  padding: 52px 0;
  width: 100%;
  align-items: center;
}
.news .news__news-items {
  gap: 30px;
  padding: 52px 0;
  column-gap: 30px;
  flex-wrap: wrap;
  justify-content: center;
}

@media (max-width: 1440px) {
  .news__news-items {
    justify-content: center;
  }
}
@media (max-width: 840px) {
  .news__news-items {
    justify-content: flex-start;
  }
}
@media (max-width: 670px) {
  .news__news-items {
    justify-content: center;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/News/styles.sass"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,WAAA;EACA,mBAAA;AACJ;AAAI;EACI,SAAA;EACA,eAAA;EACA,gBAAA;EACA,eAAA;EACA,uBAAA;AAER;;AAAA;EACI;IACI,uBAAA;EAGN;AACF;AAHA;EACI;IACI,2BAAA;EAKN;AACF;AALA;EACI;IACI,uBAAA;EAON;AACF","sourcesContent":[".news\n    padding: 52px 0\n    width: 100%\n    align-items: center\n    .news__news-items\n        gap: 30px\n        padding: 52px 0\n        column-gap: 30px\n        flex-wrap: wrap\n        justify-content: center\n\n@media (max-width: 1440px)\n    .news__news-items\n        justify-content: center\n@media (max-width: 840px)\n    .news__news-items\n        justify-content: flex-start\n@media (max-width: 670px)\n    .news__news-items\n        justify-content: center"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
