import Button from "../../UI/Button";
import "./styles.sass";

// PAGINATION COMPONENT
const Pagination = ({ currentPage, numberOfPages, totalNews }) => {

    const pageLinks = [];
    if (window.innerWidth <= 670) {
        // Always show the first page
        pageLinks.push(1);
    
        // If current page is one of the first two
        if (currentPage <= 2) {
            pageLinks.push(2, "...");
        }
        // If current page is one of the last two
        else if (currentPage >= numberOfPages - 1) {
            pageLinks.push("...", numberOfPages - 1);
        }
        // For middle pages, show current page with ellipses on either side
        else {
            pageLinks.push("...", currentPage, "...");
        }
    
        // Always show the last page
        pageLinks.push(numberOfPages);
    } else {
        if (numberOfPages <= 7) {
            for (let i = 1; i <= numberOfPages; i++) {
                pageLinks.push(i);
            }
        } else {
            // Always show the first page
            pageLinks.push(1);
    
            // If current page is one of the first 4
            if (currentPage <= 4) {
                pageLinks.push(2, 3, 4, 5, "...");
            }
            // If current page is one of the last 4
            else if (currentPage >= numberOfPages - 3) {
                pageLinks.push("...", numberOfPages - 4, numberOfPages - 3, numberOfPages - 2, numberOfPages - 1);
            }
            // For middle pages, show current page and its neighbors
            else {
                pageLinks.push("...", currentPage - 1, currentPage, currentPage + 1, "...");
            }
    
            // Always show the last page
            pageLinks.push(numberOfPages);
        }
    }

    // LAYOUT
    return (
        <div className="flex flex_space-between pagination">
            <Button className="btn_sm btn_outline" icon="icon-solar_alt-arrow-left-outlie left" />
            <div className="flex pagination__buttons">
                {pageLinks.map((page, index) => {
                    if (page === "...") {
                        return <Button key={index} className="btn_outline btn_sm" text="..." />
                    }
                    return (
                        <Button
                            key={index}
                            className={currentPage === page ? "btn_sm" : 'btn_outline btn_sm'}
                            text={page}
                        />
                    );
                })}
            </div>
            <Button className="btn_sm btn_outline" icon="icon-solar_alt-arrow-left-outlie right" />
        </div>
    );
};

// EXPORTING COMPONENT
export default Pagination;