import React, {useState, useEffect, Fragment} from "react";
import { initializeSwiper } from "../../utils/swiper/swiperInit";
import Button from "../../components/UI/Button";
import Partner from "../../components/UI/Partner";
import BusinessType from "../../components/UI/BusinessType";
import shop from "../../assets/images/Shop.png";
import cashdesk from "../../assets/images/cashdesk.png";
import WhyUsItem from "../../components/UI/WhyUsItem";
import Step from "../../components/UI/Step";
import Form from "../../components/Functional/Form";
import texnomarket from "../../assets/images/logos/texnomart.svg";
import uzum from "../../assets/images/logos/uzum.svg";
import elysee from "../../assets/images/logos/elysee.svg";
import tegen from "../../assets/images/logos/tegen.svg";
import zmarket from "../../assets/images/logos/zmarket.svg";
import dorihona from "../../assets/images/logos/dorihona.svg";
import abu from "../../assets/images/logos/abu.svg";
import anor from "../../assets/images/logos/anor.svg";
import antract from "../../assets/images/logos/antract.svg";
import bear from "../../assets/images/logos/bear.svg";
import BILLZLogo from "../../assets/images/logos/BILLZLogo 1.svg";
import evos from "../../assets/images/logos/evos.svg";
import hilol from "../../assets/images/logos/hilol.svg";
import i from "../../assets/images/logos/i.svg";
import invan from "../../assets/images/logos/invan.svg";
import kanstik from "../../assets/images/logos/kanstik.svg";
import l from "../../assets/images/logos/l.svg";
import palama from "../../assets/images/logos/palama.svg";
import payme from "../../assets/images/logos/payme.svg";
import symbol from "../../assets/images/logos/symbol.svg";
import newsItemImage from "../../assets/images/newsItem.png";
import NewsItem from "../../components/UI/NewsItem/index"
import Review from "../../components/UI/Review";
import avatar from "../../assets/images/reviewAvatar.png";
import PartnerItem from "../../components/UI/Partner/Partneritem";

import "../NewsInner/styles.sass"
import "./styles.sass";
import { useSelector } from "react-redux";
import { translate } from "../../translations/translate";
import Modal from "react-modal";
import exe from "../../assets/images/exe.png";



// MAIN PAGE
const Main = () => {

    // LANGUAGE
    const language = useSelector(state => state.language.currentLanguage);
    const __ = (key) => translate(language, key);

    // CALLBACKS
    const goToPanel = function() {
        window.location.href = "http://management.epos.uz";
    };
    const [modal , setModal] = useState(false)
    if (modal === true){
        document.querySelector("html").style.overflow = "hidden"
    } else {
        document.querySelector("html").style.overflow = "scroll"
    }
    const goToForm = function() {
        // const stepsBlock = document.querySelector("#steps");
        // stepsBlock.scrollIntoView({ behavior: 'smooth' });
        setModal(true)

    };

    let numberOfSlides = 10;

    // INITIALIZING SWIPER
    useEffect(() => {
        const swiper = initializeSwiper();
        // Cleanup (if needed) when the component unmounts
        return () => {
        };
    }, [])

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    window.addEventListener("resize", () => {
        setWindowWidth(window.innerWidth);
    })

    const [contentTab, setContentTab] = useState(1);

    function updateTab(id) {
        setContentTab(id)
    }
    const closeModal = function () {
        setModal(false);
    };


    // LAYOUT
    return (
        <div className="main-page flex flex_column">
            <div className={"modal"}>
                <Modal
                    className="delete-modal"
                    isOpen={modal}
                    onRequestClose={closeModal}
                >
                    <div className="modal-bottom">
                        <div className="component-modal-window">
                            <div className="modal-inner">
                                <div className="modal-title">
                                    <h1 className="modal-window-title">
                                        Download Cashdesk
                                    </h1>
                                </div>
                                <i
                                    className="icon-close modal-close"
                                    onClick={() => {
                                        setModal(false);
                                    }}
                                ></i>
                                <div className="modal-body">
                                    <div className="window-modal-text">
                                        <p className="window-text">
                                            Choose an appropriate format for your computer.
                                        </p>
                                    </div>
                                    <div className="modal-file bgw">
                                        <div className="file">
                                            <img className="file-image" src={exe} alt="logo" />
                                            <div className="file-text">
                                                <p className="text">
                                                    For x32-x64 bit
                                                </p>
                                                <p className="text-size">
                                                    138MB
                                                </p>
                                            </div>
                                        </div>

                                        <a
                                            href="https://github.com/epos-github/epos-demo/releases/download/v1.0.0/E-POS.DEMO.Setup.1.0.0.exe"
                                            download="cashdesk.exe"
                                            className="download-link"
                                        >
                                            <i className="icon-download"></i>
                                        </a>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
            <div className="main-container">
                <div className="intro flex flex_column align_items-center">
                    <div className="intro-inner flex flex_column align_items-center">
                        <div className="intro_title">
                            <h1 className="title">{__("POS System for")} <span className="title_primary"> {__("automation")} </span> {__("work with Taxes Office")}</h1>
                        </div>
                        <div className="intro_subtitle">
                            <p className="subtitle">
                            {__("Our software allows customers to manage their business efficiently, providing accounting for goods, services, payments, sales analysis and more. We also provide the ability to accept electronic payments and work with various payment systems. Our solution helps businesses become more competitive and successful by providing all the tools they need to grow and manage.")}
                            </p>
                        </div>
                        <div className="intro_btn flex">
                            <Button className="btn_lg" text="Try Demo" onClick={goToForm} />
                            <Button className="btn_outline btn_lg" text="Sign up" onClick={goToPanel} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="intro-image">
                <img className="img" src={cashdesk} alt="" />
            </div>
            <div className="partners">
                <div className="main-container">
                    <div className="partners-title">
                        <h3 className="title">{__("We are")} <span className="title_primary"> {__("working with")} </span></h3>
                    </div>
                    {windowWidth> 940 &&
                        <PartnerItem/>
                    }
                    {windowWidth < 940 &&
                        <>
                        <div className="swiper news-inner__slider full-width flex flex_column">
                            <div className="news-inner__slider__header flex flex_space-between"/>
                            <div className="swiper-wrapper news-inner__slider__items">
                                {
                                    Array.from({length: numberOfSlides}).map((_, index) => (
                                        <Fragment key={index}>
                                            <Partner className="swiper-slide " firstImage={texnomarket} />
                                            <Partner className="swiper-slide" firstImage={elysee} />
                                            <Partner className="swiper-slide" firstImage={uzum} />
                                            <Partner className="swiper-slide" firstImage={tegen} />
                                            <Partner className="swiper-slide" firstImage={zmarket} />
                                            <Partner className="swiper-slide" firstImage={dorihona} />
                                            <Partner className="swiper-slide" firstImage={abu} />
                                            <Partner className="swiper-slide" firstImage={anor} />
                                            <Partner className="swiper-slide" firstImage={antract} />
                                            <Partner className="swiper-slide" firstImage={bear} />
                                        </Fragment>
                                    ))
                                }
                            </div>
                        </div>
                        <div className="swiper news-inner__slider full-width flex flex_column">
                            <div className="news-inner__slider__header flex flex_space-between"/>
                            <div className="swiper-wrapper news-inner__slider__items">
                                {
                                    Array.from({length: numberOfSlides}).map((_, index) => (
                                        <Fragment key={index}>
                                            <Partner className="swiper-slide" firstImage={BILLZLogo} />
                                            <Partner className="swiper-slide" firstImage={evos} />
                                            <Partner className="swiper-slide" firstImage={hilol} />
                                            <Partner className="swiper-slide" firstImage={i} />
                                            <Partner className="swiper-slide" firstImage={invan} />
                                            <Partner className="swiper-slide" firstImage={kanstik} />
                                            <Partner className="swiper-slide" firstImage={l} />
                                            <Partner className="swiper-slide" firstImage={palama} />
                                            <Partner className="swiper-slide" firstImage={payme} />
                                            <Partner className="swiper-slide" firstImage={symbol} />
                                        </Fragment>

                                    ))
                                }
                            </div>
                        </div>
                     </>
                    }



                </div>
            </div>

            {/* </div> */}
            <div className="businesses">
                <div className="main-container">
                    <div className="business_title">
                        <h1 className="title"> {__("We're")} <span className="title_primary"> {__("the right fit")}  </span> {__("for these businesses")} </h1>
                    </div>
                    <div className="business-block">
                        <div className="block-top flex">
                            <BusinessType text="Grocery Stores" onClick={() => updateTab(1)}/>
                            <BusinessType text="Furniture Stores" onClick={() => updateTab(2)}/>
                            <BusinessType text="Electronics Stores" onClick={() => updateTab(3)}/>
                            <BusinessType text="Supplies Stores" onClick={() => updateTab(4)}/>
                            <BusinessType text="Catering Outlets" onClick={() => updateTab(5)}/>
                            <BusinessType text="Clothing and Footwear Stores" onClick={() => updateTab(6)}/>
                        </div>
                        {/* <div className={contentTab === 1 ? "tabs-content_active" : "tabs-content"}>
                            <div className="tabs-container flex  flex_space-between">
                                <div className="left-side flex flex_column flex_space-between">
                                    <div className="left-side_content">
                                        <p className="text">
                                            test1
                                        </p>
                                        <p className="text">
                                            Platea urna sed praesent hac aliquam viverra nulla congue.
                                            Tincidunt diam scelerisque bibendum vel. Habitasse vitae risus eu mauris.
                                            Interdum non in dignissim nec nisi luctus. Quam at est ut sagittis pretium
                                            tortor elementum blandit ut. Hac pretium quam diam odio ipsum. Feugiat netus
                                            sed amet et elementum eu. Pellentesque eros id sapien congue nunc in ut cursus.
                                            Non curabitur platea tincidunt.
                                        </p>
                                    </div>
                                    <div className="btn-container flex">
                                        <Button className="btn_lg" text="Try Demo"/>
                                        <Button className="btn_outline btn_lg" text="Sign Up"/>
                                    </div>
                                </div>
                                <div className="right-side">
                                    <img src={shop} alt="Business image" className="img" />
                                </div>
                            </div>
                        </div> */}
                        {/* <div className={contentTab === 2 ? "tabs-content_active" : "tabs-content"}>
                            <div className="tabs-container flex  flex_space-between">
                                <div className="left-side flex flex_column flex_space-between">
                                    <div className="left-side_content">
                                    <p className="text">
                                        test2
                                    </p>
                                    <p className="text">
                                        Platea urna sed praesent hac aliquam viverra nulla congue.
                                        Tincidunt diam scelerisque bibendum vel. Habitasse vitae risus eu mauris.
                                        Interdum non in dignissim nec nisi luctus. Quam at est ut sagittis pretium
                                        tortor elementum blandit ut. Hac pretium quam diam odio ipsum. Feugiat netus
                                        sed amet et elementum eu. Pellentesque eros id sapien congue nunc in ut cursus.
                                        Non curabitur platea tincidunt.
                                    </p>
                                    </div>
                                    <div className="btn-container flex">
                                        <Button className="btn_lg" text="Try Demo"/>
                                        <Button className="btn_outline btn_lg" text="Sign Up"/>
                                    </div>
                                </div>
                                <div className="right-side">
                                    <img src={shop} alt="Business image" className="img" />
                                </div>
                            </div>
                        </div> */}
                    </div>

                </div>
            </div>
            <div className="why-us">
                <div className="main-container">
                    <div className="why-us_title">
                        <h3 className="title">{__("Why")} <span className="title_primary"> {__("us?")} </span></h3>
                    </div>
                    <div className="why-us_container flex flex_space-between">
                        <WhyUsItem icon="icon-ic_round-point-of-sale" heading="Point of Sale Automation" text="When adding goods, the UPSI and category will be filled automatically, based on data from tasnif.soliq.uz."/>
                        <WhyUsItem icon="icon-solar_graph-up-bold" heading="Grocery Stores" text="Admin panel, sales history, inventory data and more!"/>
                        <WhyUsItem icon="icon-solar_cloud-upload-bold" heading="Grocery Stores" text="No need to buy an online cash register - the program acts as a virtual cash register."/>
                        <WhyUsItem icon="icon-solar_pen-2-bold" heading="Automatic filling of the UPSU" text="When adding goods, the UPSI and category will be filled automatically, based on data from tasnif.soliq.uz."/>
                        <WhyUsItem icon="icon-solar_printer-bold" heading="Connected equipment" text="Various types of thermal printers and barcode scanners are supported."/>
                        <WhyUsItem icon="icon-solar_chat-round-call-bold" heading="Technical support and assistance in work" text="Our specialists are always ready to help with any difficulties and answer any questions related to the program operation!"/>
                    </div>

                </div>
            </div>


            {/* <div className="news">
                <div className="swiper news-inner__slider full-width flex flex_column">
                    <div className="main-container slider-header_inner flex flex_space-between align_items-center">
                        <div className="title">
                            <h1 className="news-inner__slider__header__heading"><span className="news-inner__slider__header__heading_colored">News </span>in our blog</h1>
                        </div>

                        <div className="news-inner__slider__header__buttons flex">
                            <Button
                                className="btn_sm swiper-button-prev"
                                icon="icon-solar_alt-arrow-left-outlie left"
                            />
                            <Button
                                className="btn_sm swiper-button-next"
                                icon="icon-solar_alt-arrow-left-outlie right"
                            />
                        </div>
                    </div>
                    <div className="swiper-wrapper news-inner__slider__items">
                        {
                            Array.from({length: numberOfSlides}).map((_, index) => (
                                <NewsItem
                                    key={index}
                                    className="swiper-slide"
                                    image={newsItemImage}
                                    heading="News About SME"
                                    text="There will be given some news in our blog posts, so users will be able to read and observe more knowledges."
                                    date="20 minutes ago"
                                />
                            ))
                        }
                    </div>
                    <div className="swiper-pagination news-inner__slider__pagination"></div>
                </div>
            </div> */}

            {/* <div className="reviews">
                <div className="swiper news-inner__slider full-width flex flex_column">
                    <div className="flex  slider-header_inner flex_space-between main-container align_items-center">
                        <div className="title">
                            <h1 className="news-inner__slider__header__heading">Our clients’ <span className="news-inner__slider__header__heading_colored">reviews</span></h1>
                        </div>

                        <div className="news-inner__slider__header__buttons flex">
                            <Button
                                className="btn_sm swiper-button-prev"
                                icon="icon-solar_alt-arrow-left-outlie left"
                            />
                            <Button
                                className="btn_sm swiper-button-next"
                                icon="icon-solar_alt-arrow-left-outlie right"
                            />
                        </div>
                    </div>
                    <div className="swiper-wrapper news-inner__slider__items">
                        {
                            Array.from({length: numberOfSlides}).map((_, index) => (
                                <Review key={index} className="swiper-slide" avatar={avatar} userName="Ibrahim Mahmutov" business="Korzinka" text="There will be given some news in our blog posts, so users will be able to read and observe more knowledges."/>
                            ))
                        }
                    </div>
                    <div className="swiper-pagination news-inner__slider__pagination"></div>
                </div>
            </div> */}


            <div className="steps" id="steps">
                <div className="main-container">
                    <div className="steps-container">
                        <div className="steps_title">
                            <h3 className="title"> <span className="title_primary"> {__("Steps")} </span> {__("for receiving our product")} </h3>
                        </div>
                        <div className="steps-block flex flex_space-between">
                            <div className="step_items">
                                <Step stepNumber="1" heading="Sign up in Admin Panel" text="Begin by signing up on the admin panel. This will grant you access to the suite of tools and features essential for leveraging our product. Simply provide your details, choose a secure password, and you'll be on your way to experiencing everything our product has to offer."/>
                                <Step stepNumber="2" heading="Incorporate a Business" text="Next, incorporate your business right within our platform. By adding your company details, you establish your business's presence and unlock specific functionalities tailored for enterprises."/>
                                <Step stepNumber="3" heading="Receive Fiscal Module" text="Once your business is incorporated, you'll receive a fiscal module. This integral component ensures seamless financial operations and compliance within our platform. It's designed to assist with tax calculations, financial reporting, and other fiscal responsibilities, making your business operations smooth and efficient."/>
                                <Step stepNumber="4" heading="Receive a Cashdesk" text="After setting up the fiscal module, you'll be provided with a cashdesk. This essential tool facilitates all your transactional needs, from processing payments to handling receipts. Tailored for businesses of all sizes, our cashdesk ensures a smooth, secure, and efficient point-of-sale experience, allowing you to serve your customers with confidence and ease."/>
                            </div>
                            <div className="form flex">
                                <Form/>
                            </div>
                        </div>


                    </div>

                </div>
            </div>
        </div>
    );
};

// EXPORTING PAGE
export default Main;
