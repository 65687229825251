import { useState } from "react";
import {useSelector} from "react-redux";
import Modal from "react-modal";
import Button from "../../components/UI/Button";
import {translate} from "../../translations/translate";
import "./styles.sass";
import cashdesk1 from "../../assets/images/shop-screenshot.jpg";
import cashdesk2 from "../../assets/images/cashdesk-screenshot.png";
import cashdesk3 from "../../assets/images/managment-screenshot.png";
import logo from "../../assets/svg/logoMini.svg";

Modal.setAppElement("#root");

// OUR PRODUCT PAGE
const OurProduct = () => {
    // LANGUAGE
    const language = useSelector(state => state.language.currentLanguage);
    const __ = (key) => translate(language, key);
    // USE STATE
    const [importModalOpen, setImportModalOpen] = useState(false);
    // Hidden scroll
    if (importModalOpen === true){
        document.querySelector("html").style.overflow = "hidden"
    } else {
        document.querySelector("html").style.overflow = "scroll"
    }
    // CALLBACK FOR CLOSING MODAL
    const closeModal = function () {
        setImportModalOpen(false);
    };

    // LAYOUT
    return (
        <div className="our-product flex flex_column">
            <div className="main-container">
                {/* <div className="shadow-box flex flex_column video">
                    <div className="video__header">
                        <h1>Cashdesk</h1>
                    </div>
                    <div className="video__player">
                        <iframe
                            width="100%"
                            height="100%"
                            src="https://www.youtube.com/embed/mWRsgZuwf_8?si=FpLLvz_FjtnMTbQG"
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowfullscreen>
                        </iframe>
                    </div>
                </div> */}
                <div className="flex flex_column our-product__blocks">
                    <div className="flex shadow-box our-product__blocks__block">
                        <img src={cashdesk1} alt="product" className="our-product__blocks__block__image" />
                        <p className="our-product__blocks__block__text _right-margin">
                            {__("The E-POS Shop program is designed for retail outlets (wholesale and retail), can also be suitable for cafes, restaurants, points of sale of household appliances and many others. The advantage of the program is direct integration with the tax committee and the possibility of instant fiscalization of payments. To get started, you will need to purchase software and get a fiscal module from the Tax Committee. For the program to work, you only need a personal computer (or a cash register monoblock), a 2D scanner and a printer for printing receipts.")}
                        </p>
                    </div>
                    <div className="flex shadow-box our-product__blocks__block">
                        <p className="our-product__blocks__block__text _left-margin">
                            {__("The E-POS Cashdesk program is designed for business automation. The program has a nice design, flexible configuration and fast operation. The program is paired with the E-POS Management control panel. It offers business management, viewing sales statistics and employee management.")}
                            <br/>
                            <br/>
                            {__("Initial connection")} - {__("In development")} <br/>
                            {__("Monthly subscription fee")} - {__("In development")}
                        </p>
                        <img src={cashdesk2} alt="product" className="our-product__blocks__block__image" />
                    </div>
                    <div className="flex shadow-box our-product__blocks__block">
                        <img src={cashdesk3} alt="product" className="our-product__blocks__block__image" />
                        <p className="our-product__blocks__block__text _right-margin">
                            {__("The E-POS Management Dashboard is a website dashboard for the E-POS Cashdesk product. It is a panel for managing business, employees, viewing sales data and maintaining statistics.")}
                            <br/>
                            <br/>
                            {__("Initial connection")} - {__("In development")} <br/>
                            {__("Monthly subscription fee")} - {__("In development")}
                        </p>
                    </div>
                    <Button text="Download" className="btn_download" onClick={() => setImportModalOpen(true)} />
                </div>
            </div>
            <Modal
                className="delete-modal"
                isOpen={importModalOpen}
                onRequestClose={closeModal}
            >
                <div className="modal-bottom">
                    <div className="component-modal-window">
                        <div className="modal-inner">
                            <div className="modal-title">
                                <h1 className="modal-window-title">
                                    Download Cashdesk
                                </h1>
                            </div>
                            <i
                                className="icon-close modal-close"
                                onClick={() => {
                                    setImportModalOpen(false);
                                }}
                            ></i>
                            <div className="modal-body">
                                <div className="window-modal-text">
                                    <p className="window-text">
                                        Choose an appropriate format for your computer.
                                    </p>
                                </div>
                                <div className="modal-file">
                                    <div className="file">
                                        <img className="file-image" src={logo} alt="logo" />
                                        <div className="file-text">
                                            <p className="text">
                                                For x32 bit
                                            </p>
                                            <p className="text-size">
                                                137MB
                                            </p>
                                        </div>
                                    </div>

                                    <a
                                        href="https://github.com/epos-github/epos-appx32/releases/download/0.2.16/E-POS-Cashdesk-Setup-0.2.16.exe"
                                        download="cashdesk.exe"
                                        className="download-link"
                                    >
                                        <i className="icon-download"></i>
                                    </a>
                                </div>
                                <div className="modal-file">
                                    <div className="file">
                                        <img className="file-image" src={logo} alt="logo" />
                                        <div className="file-text">
                                            <p className="text">
                                                For x64 bit
                                            </p>
                                            <p className="text-size">
                                                139MB
                                            </p>
                                        </div>
                                    </div>

                                    <a
                                        href="https://github.com/epos-github/epos-app/releases/download/0.2.16/E-POS-Cashdesk-Setup-0.2.16.exe"
                                        download="cashdesk.exe"
                                        className="download-link"
                                    >
                                        <i className="icon-download"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            {importModalOpen && <div className="layout-cashdesk"></div>}
        </div>
    );
};

// EXPORT
export default OurProduct;
