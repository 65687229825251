
import elysee from "../../../../assets/images/logos/elysee.svg";
import tegen from "../../../../assets/images/logos/tegen.svg";
import zmarket from "../../../../assets/images/logos/zmarket.svg";
import dorihona from "../../../../assets/images/logos/dorihona.svg";
import abu from "../../../../assets/images/logos/abu.svg";
import anor from "../../../../assets/images/logos/anor.svg";
import antract from "../../../../assets/images/logos/antract.svg";
import bear from "../../../../assets/images/logos/bear.svg";
import BILLZLogo from "../../../../assets/images/logos/BILLZLogo 1.svg";
import evos from "../../../../assets/images/logos/evos.svg";
import hilol from "../../../../assets/images/logos/hilol.svg";
import i from "../../../../assets/images/logos/i.svg";
import invan from "../../../../assets/images/logos/invan.svg";
import kanstik from "../../../../assets/images/logos/kanstik.svg";
import l from "../../../../assets/images/logos/l.svg";
import palama from "../../../../assets/images/logos/palama.svg";
import payme from "../../../../assets/images/logos/payme.svg";
import symbol from "../../../../assets/images/logos/symbol.svg";
import texnomarket from "../../../../assets/images/logos/texnomart.svg";
import uzum from "../../../../assets/images/logos/uzum.svg";
import { useEffect, useState } from "react";


const  PartnerItem = ({ backgroundColor, className, firstImage, secondImage, boxBackgroundColor, index}) => {

    const partnerDataFirst = [
        {
            id: 1,
            img: elysee,
        },
        {
            id: 2,
            img: tegen,
        },
        {
            id: 3,
            img: zmarket
        },
        {
            id: 4,
            img: dorihona
        },
        {
            id: 5,
            img: abu,
        },
        {
            id: 6,
            img: anor,
        },
        {
            id: 7,
            img: antract
        },
        {
            id: 8,
            img: bear
        },
        {
            id:9,
            img: BILLZLogo
        },
        {
            id: 10,
            img: evos,
        },
    ];
    const partnerDataSecond = [
        {
            id: 11,
            img: hilol
        },
        {
            id: 12,
            img: i
        },
        {
            id:13,
            img: kanstik,
            boxBackgroundColor: "blue"
        },
        {
            id: 14,
            img: l,
        },
        {
            id: 15,
            img: palama
        },
        {
            id: 16,
            img: payme
        },
        {
            id:17,
            img: texnomarket
        },
        {
            id: 18,
            img: symbol
        },
        {
            id: 19,
            img: uzum
        },
        {
            id: 20,
            img: invan
        }
    ];

    const [flipped, setFlipped] = useState(null);

    useEffect(() => {
        const flipInterval = setInterval(() => {
            const randomIndex = Math.floor(Math.random() * partnerDataFirst.length);
            setFlipped(null);
            setTimeout(() => {
                setFlipped(randomIndex);
            }, 2000);
        }, 5000);
    
        return () => clearInterval(flipInterval); // Clear the interval on component unmount.
    }, []);

    return (
        <div className={`partners-block flex flex_space-between ${className} `} >
            {partnerDataFirst.map((item, i) => (
                <div key={i} id={item?.id} className="partners-box">
                    <div className={`partner-box_flip shadow-box shadow-box_business-partner _bra-16 shadow-box_${backgroundColor} ${item?.boxBackgroundColor} ${flipped === i ? 'flipped' : ''}`}>
                        <img src={partnerDataFirst?.[i]?.img}alt="logo" className="img-logo" />
                    </div>
                    <div className={`partner-box_flip shadow-box shadow-box_business-partner _bra-16 shadow-box_${backgroundColor} ${flipped === i ? 'flipped' : ''}`}>
                        <img src={partnerDataSecond?.[i]?.img} alt="logo" className="img-logo " />
                    </div>
                </div>
            ))}
        </div>
    );
}
export default PartnerItem;