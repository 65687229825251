// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.shadow-box_description {
  display: flex;
  align-items: center;
  width: 100%;
  column-gap: 30px;
}
@media screen and (max-width: 920px) {
  .shadow-box_description {
    justify-content: center;
    flex-wrap: wrap;
  }
}
.shadow-box_description .shadow-box_text {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  margin: 24px 0 24px 24px;
  width: 50%;
}
@media screen and (max-width: 920px) {
  .shadow-box_description .shadow-box_text {
    width: 100%;
  }
}
.shadow-box_description .shadow-box_text .about-title .title {
  font-size: 52px;
  font-weight: 600;
}
@media screen and (max-width: 400px) {
  .shadow-box_description .shadow-box_text .about-title .title {
    font-size: 36px;
  }
}
.shadow-box_description .shadow-box_image {
  width: 50%;
}
@media screen and (max-width: 920px) {
  .shadow-box_description .shadow-box_image {
    width: 100%;
  }
}
.shadow-box_description .shadow-box_image .image {
  border-radius: 32px;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/UI/AboutDescription/styles.sass"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,WAAA;EACA,gBAAA;AACJ;AAAI;EALJ;IAMQ,uBAAA;IACA,eAAA;EAGN;AACF;AAHI;EACI,aAAA;EACA,sBAAA;EACA,aAAA;EACA,wBAAA;EACA,UAAA;AAKR;AAJQ;EANJ;IAOQ,WAAA;EAOV;AACF;AANY;EAGI,eAAA;EACA,gBAAA;AAMhB;AATgB;EADJ;IAEQ,eAAA;EAYlB;AACF;AAVI;EACI,UAAA;AAYR;AAXQ;EAFJ;IAGQ,WAAA;EAcV;AACF;AAdQ;EACI,mBAAA;EACA,WAAA;AAgBZ","sourcesContent":[".shadow-box_description\n    display: flex\n    align-items: center\n    width: 100%\n    column-gap: 30px\n    @media screen and (max-width: 920px)\n        justify-content: center\n        flex-wrap: wrap\n    .shadow-box_text\n        display: flex\n        flex-direction: column\n        row-gap: 24px\n        margin: 24px 0 24px 24px\n        width: 50%\n        @media screen and (max-width: 920px)\n            width: 100%\n        .about-title\n            .title\n                @media screen and (max-width : 400px)\n                    font-size: 36px\n                font-size: 52px\n                font-weight: 600\n    .shadow-box_image\n        width: 50%\n        @media screen and (max-width: 920px)\n            width: 100%\n        .image\n            border-radius: 32px\n            width: 100%"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
