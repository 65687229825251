// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.shadow-box_business-vision {
  display: flex;
  align-items: center;
  column-gap: 30px;
  width: 100%;
}
@media screen and (max-width: 920px) {
  .shadow-box_business-vision {
    justify-content: center;
    flex-wrap: wrap-reverse;
  }
}
.shadow-box_business-vision .shadow-box__image {
  width: 50%;
}
@media screen and (max-width: 920px) {
  .shadow-box_business-vision .shadow-box__image {
    width: 100%;
  }
}
.shadow-box_business-vision .shadow-box__image .image {
  border-radius: 32px;
  width: 100%;
}
.shadow-box_business-vision .shadow-box__text {
  width: 50%;
  margin: 24px 24px 24px 0;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}
@media screen and (max-width: 920px) {
  .shadow-box_business-vision .shadow-box__text {
    width: 100%;
    margin: 24px;
  }
}
.shadow-box_business-vision .shadow-box__text .business-heading .title {
  font-size: 42px;
  text-align: left;
}
@media screen and (max-width: 920px) {
  .shadow-box_business-vision .shadow-box__text .business-heading .title {
    text-align: center;
  }
}
@media screen and (max-width: 620px) {
  .shadow-box_business-vision .shadow-box__text .business-heading .title {
    font-size: 24px;
  }
}
.shadow-box_business-vision .shadow-box__text .business-text {
  font-size: 16px;
  font-weight: 400;
}
.shadow-box_business-vision .shadow-box__text .business-text .text {
  text-align: left;
}`, "",{"version":3,"sources":["webpack://./src/components/UI/BusinessVision/styles.sass"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,gBAAA;EACA,WAAA;AACJ;AAAI;EALJ;IAMQ,uBAAA;IACA,uBAAA;EAGN;AACF;AAHI;EACI,UAAA;AAKR;AAJQ;EAFJ;IAGQ,WAAA;EAOV;AACF;AAPQ;EACI,mBAAA;EACA,WAAA;AASZ;AARI;EACI,UAAA;EACA,wBAAA;EACA,aAAA;EACA,sBAAA;EACA,aAAA;AAUR;AATQ;EANJ;IAOQ,WAAA;IACA,YAAA;EAYV;AACF;AAXY;EACI,eAAA;EACA,gBAAA;AAahB;AAZgB;EAHJ;IAIQ,kBAAA;EAelB;AACF;AAfgB;EALJ;IAMQ,eAAA;EAkBlB;AACF;AAlBQ;EACI,eAAA;EACA,gBAAA;AAoBZ;AAnBY;EACI,gBAAA;AAqBhB","sourcesContent":[".shadow-box_business-vision\n    display: flex\n    align-items: center\n    column-gap: 30px\n    width: 100%\n    @media screen and (max-width: 920px)\n        justify-content: center\n        flex-wrap: wrap-reverse\n    .shadow-box__image\n        width: 50%\n        @media screen and (max-width: 920px)\n            width: 100%\n        .image\n            border-radius: 32px\n            width: 100%\n    .shadow-box__text\n        width: 50%\n        margin: 24px 24px 24px 0\n        display: flex\n        flex-direction: column\n        row-gap: 24px\n        @media screen and (max-width: 920px)\n            width: 100%\n            margin: 24px\n        .business-heading\n            .title\n                font-size: 42px\n                text-align: left\n                @media screen and (max-width: 920px)\n                    text-align: center\n                @media screen and (max-width: 620px)\n                    font-size: 24px\n        .business-text\n            font-size: 16px\n            font-weight: 400\n            .text\n                text-align: left\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
