export const UZ = {
    Home: "Bosh sahifa",
    About: "Biz haqimizda",
    Contacts: "Aloqa",
    "Sign up": "Ro'yxatdan o'tish",
    "Try Demo": "Sinab ko'rish",
    "POS System for": "Soliq idorasida ishlashni",
    "automation": "avtomatlashtirish",
    "work with Taxes Office": "uchun POS tizimi",
    "Our software allows customers to manage their business efficiently, providing accounting for goods, services, payments, sales analysis and more. We also provide the ability to accept electronic payments and work with various payment systems. Our solution helps businesses become more competitive and successful by providing all the tools they need to grow and manage." : "Bizning dasturimiz mijozlarga savdo, xizmat, to'lovlar, savdo tahlili va boshqa ko'plab narsalarni hisoblash orqali biznesni samarali boshqarish imkonini beradi. Shuningdek, biz elektron to'lovlarni qabul qilish va turli to'lov tizimlari bilan ishlash imkonini beramiz. Bizning yechimimiz biznesning rivojlanish va boshqarish uchun kerak bo'lgan barcha asboblar bilan ta'minlash orqali biznesni yanada raqobatbardosh va muvaffaqiyatli qiladi.",
    "We are": "Biz bilan",
    "working with": "ishlayotganlar",
    "We're": "Biz",
    "the right fit": "quyidagi bizneslar",
    "for these businesses": "uchun mos kelamiz",
    "Grocery Stores": "Oziq-ovqat do'konlari",
    "Furniture Stores": "Mebel do'konlari",
    "Electronics Stores": "Elektronika do'konlari",
    "Supplies Stores": "Ta'minot do'konlari",
    "Catering Outlets": "Ovqatlanish tarmoqlari",
    "Clothing and Footwear Stores": "Kiyim va poyafzal do'konlari",
    "Why": "Nega",
    "us?": "biz?",
    "Point of Sale Automation": "Savdo nuqtasi avtomatlashtirilishi",
    "When adding goods, the UPSI and category will be filled automatically, based on data from tasnif.soliq.uz.": "Mahsulot qo'shilganda, UPSI va kategoriya tasnif.soliq.uz ma'lumotlariga asosan avtomatik to'ldiriladi.",
    "Admin panel, sales history, inventory data and more!": "Admin paneli, sotish tarixi, zahira ma'lumotlari va boshqalar!",
    "No need to buy an online cash register - the program acts as a virtual cash register.": "Onlayn kassir apparatini sotib olishga hojat yo'q - dastur virtual kassir apparati sifatida xizmat qiladi.",
    "Automatic filling of the UPSU": "UPSU avtomatik to'ldirish",
    "Connected equipment": "Ulangan uskunalar",
    "Various types of thermal printers and barcode scanners are supported.": "Turli turi termoprinterlar va shtrix-kod skanerlarini qo'llab-quvvatlash mumkin.",
    "Technical support and assistance in work": "Texnik yordam va ishda yordam bering",
    "Our specialists are always ready to help with any difficulties and answer any questions related to the program operation!": "Bizning mutaxassislarimiz har doim dasturni ishlashga oid har qanday murakkabliklar va savollarga javob bermoqga tayyormi!",
    "Steps": "Qadam",
    "for receiving our product": "bizning mahsulotimizni olish uchun",
    "Sign up in Admin Panel": "Admin panelida ro'yxatdan o'ting",
    "Begin by signing up on the admin panel. This will grant you access to the suite of tools and features essential for leveraging our product. Simply provide your details, choose a secure password, and you'll be on your way to experiencing everything our product has to offer.": "Admin panelida ro'yxatdan o'tish bilan boshlang. Bu sizga bizning mahsulotimizni foydalanish uchun zarur bo'lgan asboblar va xususiyatlarga kirish huquqini beradi. Faqat tafsilotlaringizni kiritib, ishonchli parolni tanlang va bizning mahsulotimizning taklif qiladigan barcha narsalarni tajriba qilishingiz mumkin.",
    "Incorporate a Business": "Biznesni qo'shib qo'ying",
    "Next, incorporate your business right within our platform. By adding your company details, you establish your business's presence and unlock specific functionalities tailored for enterprises.": "Keyingi qadamda, biznesingizni to'g'ridan-to'g'ri bizning platformamizga kiritasiz. Kompaniyaning ma'lumotlarini qo'shish orqali siz biznesingizning mavjudligini aniqlaysiz va korxonalarga moslangan maxsus funktsiyalarni faollashtirasiz.",
    "Receive Fiscal Module": "Soliq modulini oling",
    "Once your business is incorporated, you'll receive a fiscal module. This integral component ensures seamless financial operations and compliance within our platform. It's designed to assist with tax calculations, financial reporting, and other fiscal responsibilities, making your business operations smooth and efficient.": "Biznesingiz kiritilganda, siz soliq modulini olasiz. Bu butunlay tarkibiy qism bizning platformamizda moliyaviy operatsiyalar va tartib-tartibga rioya qilishni ta'minlaydi. Bu naqd hisob-kitob, moliyaviy hisobot va boshqa soliq mas'uliyatlarida yordam berish uchun mo'ljalashtirilgan, bu sizning biznes faoliyatingizni hamda va samarali qiladi.",
    "Receive a Cashdesk": "Kassir apparatini oling",
    "After setting up the fiscal module, you'll be provided with a cashdesk. This essential tool facilitates all your transactional needs, from processing payments to handling receipts. Tailored for businesses of all sizes, our cashdesk ensures a smooth, secure, and efficient point-of-sale experience, allowing you to serve your customers with confidence and ease.": "Soliq modulini o'rnatgandan keyin, sizga kassir apparati beriladi. Bu zarur asbob sizning barcha tranzaktsiyaviy talablarinizi amalga oshiradi, to'lovni amalga oshirishdan kvitansiyalarni ko'rib chiqishgacha. Barcha o'lchamlardagi bizneslar uchun moslashtirilgan kassir apparatimiz savdo nuqtasida hamda, xavfsiz va samarali tajriba ta'minlaydi, bu sizga mijozlaringizga ishonch bilan va yengillik bilan xizmat ko'rsatishingizga imkon beradi.",
    "Sections": "Bo'limlar",
    "Headquarter Address": "Bosh ofis manzili",
    "Tashkent city": "Toshkent shahri",
    "Almazar district": "Almazar tumani",
    "Yangi Almazor street": "Yangi Almazor ko'chasi",
    "At E-POS Systems, we redefine the way businesses handle transactions. As a leading provider of virtual cashdesks, we harness the power of technology to offer solutions that are not only efficient but also innovative. In an era where digital transformation is paramount, our virtual cashdesks serve as a testament to our commitment to simplifying business operations. We understand the challenges of modern commerce, and our platform is meticulously designed to address them. With our state-of-the-art virtual cashdesks, businesses can ensure seamless, secure, and swift transactions, enhancing customer experience and operational efficiency.": "E-POS Systems-da biz tranzaktsiyalarni qanday boshqarishni qayta aniqlaymiz. Virtual kassir apparatlarining etakchi ta'minotchisi sifatida, biz texnologiya kuchini samarali va ham innovatsion yechimlarni taklif qilish uchun ishlatamiz. Digital o'zgarishning dolzarb bo'lgan davrda, bizning virtual kassir apparatlari biznes operatsiyalarini soddalashtirishga bo'lgan vaqtni o'z ichiga olganligimizga guvohnoma bo'ladi. Biz zamonaviy savdoning muammolarini tushunamiz va bizning platformamiz ularni hal qilish uchun diqqat bilan ishlangan. Bizning zamonaviy virtual kassir apparatlari bilan korxonalar siziqsiz, xavfsiz va tez tranzaktsiyalarni ta'minlash, mijozlar tajribasini va operatsion samaradorligini oshirish mumkin.",
    "Our company": "Bizning kompaniyamiz",
    "in numbers": "raqamlarda",
    "Here you can see our company in terms of statistics and get acquainted with its efficiency.": "Bu erda siz bizning kompaniyamizni statistika jihatidan ko'rishingiz va uning samaradorligi bilan tanishingiz mumkin.",
    "Founded in": "Tashkil topgan yili",
    "Active businesses": "Faol bizneslar",
    "Active cashdesks": "Faol kassir apparatlari",
    "Business vision": "Biznes ko'zgusi",
    "Our vision is to revolutionize the transactional landscape, making every financial touchpoint seamless, efficient, and forward-thinking. We envision a world where businesses, irrespective of their size or domain, can harness the power of digital innovation, ensuring that every transaction is not just a process, but an experience. Through our state-of-the-art virtual cashdesks, we aim to empower enterprises to operate with unmatched efficiency and to redefine customer interactions, setting a global standard for digital commerce.": "Bizning ko'zgumiz tranzaktsiyaviy manzarani inqilobga keltirish, har bir moliyaviy nuqtani siziqsiz, samarali va oldinga yonilgan qilishdan iborat. Biz olamda, o'lchami yoki sohasiga qaramasdan, bizneslar digital yangiliklar kuchini qo'llab-quvvatlash, har bir tranzaktsiyaning faqat jarayon emas, balki tajriba ekanligini ta'minlash mumkinligini ko'rib turganmiz. Bizning zamonaviy virtual kassir apparatlari orqali, biz korxonalarni tengsiz samarali ishlashga va mijozlar bilan o'zaro ta'sirni qayta aniqlashga ruhsat berishni maqsad qilamiz, digital savdo uchun global me'yori o'rnatish.",
    "Our": "Bizning",
    "contacts": "Aloqa",
    "and": "va",
    "locations": "Manzillar",
    "Tashkent": "Toshkent",
    "Almazar region, st. Yangi Almazar, 51": "Almazar tumani, Yangi Almazar ko'chasi, 51",
    "Yunusabad region, st. Dekhkanabad": "Yunusobod tumani, Dekhkonobod ko'chasi",
    "Samarkand": "Samarqand",
    "st. Makhmud Koshgariy, house 49": "Makhmud Qoshqoriy ko'chasi, uy 49",
    "Andijan": "Andijon",
    "Asaka region, st. Karvonsaroy, house 1": "Asaka tumani, Qarvonsaroy ko'chasi, uy 1",
    "Jizzakh": "Jizzax",
    "st. Shifokorlar, house 0": "Shifokorlar ko'chasi, uy 0",
    "Bukhara": "Buxoro",
    "st. Khilol Bukhariy, house 84": "Xilol Buxoriy ko'chasi, uy 84",
    "Navoi": "Navoiy",
    "Axangaran": "Ohangaron",
    "2nd microdistrict, 11a, 37": "2-mikrorayon, 11a, 37",
    // shop description translate
    "The E-POS Shop program is designed for retail outlets (wholesale and retail), can also be suitable for cafes, restaurants, points of sale of household appliances and many others. The advantage of the program is direct integration with the tax committee and the possibility of instant fiscalization of payments. To get started, you will need to purchase software and get a fiscal module from the Tax Committee. For the program to work, you only need a personal computer (or a cash register monoblock), a 2D scanner and a printer for printing receipts.": "Программа E-POS Shop предназначена для торговых точек (оптовых и розничных), может подойти так же кафе, ресторанам, точкам продажи бытовой техники и многим другим. Преимуществом программы является прямая интеграция с налоговым комитетом и возможность момментальной фискализации платежей. Для начала работы вам потребуется приобрести программное обеспечие и получить фискальный модуль от Налогового Комитета. Для работы программы вам потребуется лишь персональный компьютер (либо кассовый моноблок), 2Д сканер и принтер для печати чеков.",
    "Initial connection": "Первоначальное подключение",
    "Monthly subscription fee": "Месячная абонентская плата",
    soums: "сумов",
    "In development": "В разработке",
    "Name" : "Ism",
    "Phone number" : "Telefon raqam",
    "Message" : "Xabar",
    "I am informed about the privacy policy and consent to the processing of my personal data." : "Men maxfiylik siyosati bilan tanishdim va shaxsiy ma'lumotlarimni qayta ishlashga roziman.",
    "Our Product" : "Bizning  mahsulot",
    "E-POS Systems 2023 All rights reserved." : "E-POS tizimlari 2023 Barcha huquqlar himoyalangan.",
    "Submit" : "Yuborish",
    "LLC" : "MCHJ",

    // cashdesk description translate
    "The E-POS Cashdesk program is designed for business automation. The program has a nice design, flexible configuration and fast operation. The program is paired with the E-POS Management control panel. It offers business management, viewing sales statistics and employee management.": "Программа E-POS Cashdesk предназначена для автоматизации бизнеса. Программа имеет приятный дизайн, гибкую настройку и быструю работу. Программа работает в паре с панелью управления E-POS Management. Предлагает в себе управление бизнесом, просмотр статистики продаж и управление сотрудниками.",
    // managment description translate
    "The E-POS Management Dashboard is a website dashboard for the E-POS Cashdesk product. It is a panel for managing business, employees, viewing sales data and maintaining statistics.": "Панель управления E-POS Management является веб-сайт панелью для управления для продукта E-POS Cashdesk. Является панелью для управдения бизнесом, сотрудниками, просмотра данных по продажам и ведения статистики."
};