// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-privacy {
  margin: 0 auto;
  padding: 24px;
}
.page-privacy h1 {
  margin: 0 0 20px 0;
  color: #333333;
  font-size: 30px;
  font-weight: 600;
  line-height: 36px;
  text-align: center;
}
.page-privacy h2 {
  margin: 20px 0;
  color: #555555;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  text-align: center;
}
.page-privacy h3 {
  margin: 8px 0;
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
}
.page-privacy p {
  margin-bottom: 15px;
}
.page-privacy ul {
  margin: 0;
  padding: 0;
}`, "",{"version":3,"sources":["webpack://./src/pages/PrivacyPolicy/styles.sass"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,aAAA;AACF;AACE;EACE,kBAAA;EACA,cAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;AACJ;AACE;EACE,cAAA;EACA,cAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;AACJ;AACE;EACE,aAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;AACJ;AACE;EACE,mBAAA;AACJ;AACE;EACE,SAAA;EACA,UAAA;AACJ","sourcesContent":[".page-privacy\n  margin: 0 auto\n  padding: 24px\n\n  h1\n    margin: 0 0 20px 0\n    color: #333333\n    font-size: 30px\n    font-weight: 600\n    line-height: 36px\n    text-align: center\n\n  h2\n    margin: 20px 0\n    color: #555555\n    font-size: 24px\n    font-weight: 600\n    line-height: 32px\n    text-align: center\n\n  h3\n    margin: 8px 0\n    font-size: 18px\n    font-weight: 700\n    line-height: 28px\n\n  p\n    margin-bottom: 15px\n\n  ul\n    margin: 0\n    padding: 0\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
