import { useSelector } from "react-redux";
import "./styles.sass";
import { translate } from "../../../translations/translate";

// EPOS DESCRIPTION COMPONENT
const AboutDescription = ({heading, text, image}) => {

    // LANGUAGE
    const language = useSelector(state => state.language.currentLanguage);
    const __ = (key) => translate(language, key);

    // LAYOUT
    return (
        <div className="shadow-box shadow-box_description">
            <div className="shadow-box_text">
                <div className="about-title">
                    <h1 className="title">{__(heading)}</h1>
                </div>
                <div className="about-text">
                    <p className="text">{__(text)}</p>
                </div>
               
            </div>
            <div className="shadow-box_image">
                <img src={image} alt="shop" className="image" />
            </div>
        </div>
    );
};

// EXPORTING COMPONENT
export default AboutDescription;