import { useEffect, useState } from "react";
import { initializeSwiper } from "../../utils/swiper/swiperInit";
import mainImage from "../../assets/images/newsInnerShop.png";
import NewsItem from "../../components/UI/NewsItem";
import newsItemImage from "../../assets/images/newsItem.png";
import newsItemImage2 from "../../assets/images/newsItem2.png";
import Button from "../../components/UI/Button";
import "./styles.sass";

// NEWS INNER PAGE (IF NEWS IS CLICKED ON NEWS PAGE WE SEE ITS DETAILS)
const NewsInner = () => {

    // USE STATE
    let numberOfSlides = 5;

    if(window.innerWidth <= 785) {
        numberOfSlides = 3;
    }

    // INITIALIZING SWIPER
    useEffect(() => {
        const swiper = initializeSwiper();
        // Cleanup (if needed) when the component unmounts
        return () => {
        };
    }, [])

    // LAYOUT
    return (
        <div className="news-inner flex flex_column">
            <div className="news-inner__header flex flex_column">
                <div className="news-inner__header__naming flex flex_space-between">
                    <div>
                        <h1>News About SME</h1>
                        <p>There will be given some news in our blog posts, so users will be able to read and observe more knowledges.</p>
                    </div>
                    <div className="news-inner__header__naming__date">
                        <p className="news-inner__header__naming__date__text">20 minutes ago</p>
                    </div>
                </div>
                <img src={mainImage} alt="shop" className="news-inner__header__image" />
            </div>
            <h5 className="news-inner__description">
                Lorem ipsum dolor sit amet consectetur. Donec id quis erat pulvinar suspendisse quis lacus dignissim consectetur. Nec commodo duis dolor hendrerit malesuada augue tortor consectetur. At interdum sit posuere fermentum convallis duis. A dolor duis odio dignissim aliquam purus. Vel quis odio vitae malesuada non sagittis neque pellentesque ultrices. Facilisis feugiat viverra eget aliquet aliquam mauris penatibus faucibus nec. Lorem ut vulputate tristique nisl egestas condimentum mauris. Id ac amet a enim egestas feugiat eget dolor tortor. Cursus vulputate sapien pulvinar orci tempor. Eget dolor massa nullam purus nunc arcu in sed dui. Eget velit phasellus sit diam dui orci.

                Amet ullamcorper aliquet eget tellus donec vitae. Lacus vitae laoreet vestibulum nulla faucibus amet. Sagittis arcu in pellentesque pretium dignissim mattis massa velit. Placerat ipsum enim magna amet nulla nulla consectetur. Nec hendrerit nulla quis lobortis quis. Integer tortor fringilla dui cras nam est.

                Ut netus faucibus elementum duis nunc et ipsum. Adipiscing nisl praesent euismod odio. In tempor porta eget in diam condimentum quis tortor. Tempus gravida tincidunt fames tristique quis orci tristique nulla. Luctus ultrices accumsan turpis nunc.

                In id volutpat purus dictumst. Nulla etiam vivamus porttitor sollicitudin. Libero in nisl egestas pulvinar ipsum molestie porttitor et feugiat. Id fermentum aliquam volutpat nascetur vitae diam sed ultricies lorem. Nulla nunc feugiat donec egestas ipsum in semper tempus. Semper venenatis non egestas amet. Enim amet velit in a id mattis. Pharetra pharetra tellus facilisis diam nibh viverra nisi. Integer sit nec mauris quis malesuada fames suspendisse. Pretium mauris dignissim quisque sit. Dui aliquet vitae nisl varius volutpat pellentesque vel id.

                Eu consectetur ipsum faucibus adipiscing. Fermentum at massa egestas donec. Sit tristique in ipsum adipiscing lobortis. Urna eget pretium quis consequat id faucibus pellentesque. Elementum integer dictumst gravida pretium cras. Sit nunc arcu tempor urna sit feugiat proin lacus scelerisque. Et nunc ipsum tincidunt urna felis et libero. Id senectus suspendisse etiam leo odio felis dui id. Convallis turpis lacus adipiscing nec fermentum lobortis commodo in. Velit accumsan aliquam placerat magna sit quam cras sapien mattis. Gravida orci quis quis magnis gravida ornare fringilla. In aliquet vulputate vel sed semper pretium donec nulla. Viverra justo tincidunt lacus fringilla fringilla orci ut commodo. Volutpat imperdiet platea neque ante ultrices ac vel erat sed.
            </h5>
            <div className="swiper news-inner__slider full-width flex flex_column">
                <div className="news-inner__slider__header flex flex_space-around">
                    <h2 className="news-inner__slider__header__heading"><span className="news-inner__slider__header__heading_colored">Relative news</span> in our blog</h2>
                    <div className="news-inner__slider__header__buttons flex">
                        <Button 
                            className="btn_sm swiper-button-prev" 
                            icon="icon-solar_alt-arrow-left-outlie left"
                        />
                        <Button 
                            className="btn_sm swiper-button-next" 
                            icon="icon-solar_alt-arrow-left-outlie right"     
                        />
                    </div>
                </div>
                <div className="swiper-wrapper news-inner__slider__items">
                    {
                        Array.from({length: numberOfSlides}).map((_, index) => (
                            <NewsItem 
                                key={index}
                                className="swiper-slide"
                                image={newsItemImage}
                                heading="News About SME"
                                text="There will be given some news in our blog posts, so users will be able to read and observe more knowledges."
                                date="20 minutes ago"
                            />
                        ))
                    }
                </div>
                <div className="swiper-pagination news-inner__slider__pagination"></div>
            </div>
        </div>
    );
};

// EXPORTING PAGE
export default NewsInner;