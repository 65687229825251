import "./styles.sass";
import { useEffect} from "react";
import { initializeSwiper } from "../../utils/swiper/swiperInit";
import AboutDescription from "../../components/UI/AboutDescription/index";
import shop from "../../assets/images/main_news_shop.jpg";
import CompanyNumber from "../../components/UI/CompanyNumber";
import Button from "../../components/UI/Button";
import BusinessVision from "../../components/UI/BusinessVision";
import business from "../../assets/images/businessVision.png";
import product1 from "../../assets/images/product1.png";
import product2 from "../../assets/images/product2.png";
import product3 from "../../assets/images/product3.png";
import product4 from "../../assets/images/product4.png";
import product5 from "../../assets/images/product5.png";
import product6 from "../../assets/images/product6.png";
import ibragim from "../../assets/images/ibragim.png";
import Product from "../../components/UI/Product";
import TeamMember from "../../components/UI/TeamMember";
import Step from "../../components/UI/Step";
import Form from "../../components/Functional/Form";
import { useSelector } from "react-redux";
import { translate } from "../../translations/translate";

// ABOUT PAGE
const About = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    // LANGUAGE
    const language = useSelector(state => state.language.currentLanguage);
    const __ = (key) => translate(language, key);

    let numberOfSlides = 10;

    // CALLBACKS
    const goToPanel = function() {
        window.location.href = "http://management.epos.uz";
    };  
    const goToForm = function() {
        const stepsBlock = document.querySelector("#steps");
        stepsBlock.scrollIntoView({ behavior: 'smooth' });
    };

    // INITIALIZING SWIPER
    useEffect(() => {
        const swiper = initializeSwiper();
        // Cleanup (if needed) when the component unmounts
        return () => {
        };
    }, [])
   
    // LAYOUT
    return (
        <div className="about flex flex_column">
            <div className="about-container">
                <div className="main-container">
                    <AboutDescription heading="E-POS Systems" text="At E-POS Systems, we redefine the way businesses handle transactions. As a leading provider of virtual cashdesks, we harness the power of technology to offer solutions that are not only efficient but also innovative. In an era where digital transformation is paramount, our virtual cashdesks serve as a testament to our commitment to simplifying business operations. We understand the challenges of modern commerce, and our platform is meticulously designed to address them. With our state-of-the-art virtual cashdesks, businesses can ensure seamless, secure, and swift transactions, enhancing customer experience and operational efficiency." image={shop}/>
                </div>
            </div>
            <div className="company-number">
                <div className="main-container">
                    <div className="company-inner">
                        <div className="company_title">
                            <h1 className="title">{__("Our company")} <span className="title_primary"> {__("in numbers")} </span> </h1>
                        </div>
                        <div className="company_text">
                            <p className="text">{__("Here you can see our company in terms of statistics and get acquainted with its efficiency.")}</p>
                        </div>
                        <div className="numbers_body flex flex_content-center">
                            <CompanyNumber value="2017" name="Founded in"/>
                            <CompanyNumber value="1 001" name="Active businesses"/>
                            <CompanyNumber value="3 800" name="Active cashdesks"/>
                        </div>
                        <div className="btn-container flex flex_content-center">
                            <Button className="btn_lg" text="Try Demo" onClick={goToForm} />
                            <Button className="btn_outline btn_lg" text="Sign up" onClick={goToPanel} />
                        </div>
                        
                    </div>
                </div>
            </div>
            <div className="business-vision">
                <div className="main-container">
                    <BusinessVision heading="Business vision" text="Our vision is to revolutionize the transactional landscape, making every financial touchpoint seamless, efficient, and forward-thinking. We envision a world where businesses, irrespective of their size or domain, can harness the power of digital innovation, ensuring that every transaction is not just a process, but an experience. Through our state-of-the-art virtual cashdesks, we aim to empower enterprises to operate with unmatched efficiency and to redefine customer interactions, setting a global standard for digital commerce." image={business}/>
                </div>
            </div>
            {/* <div className="products">
                <div className="main-container">
                    <div className="product-inner">
                        <div className="product_title">
                            <h1 className="title">Our <span className="title_primary"> Products</span> </h1>
                        </div>
                        <div className="products-container flex">
                            <Product image={product1} productName="Product 1" description="Lorem ipsum dolor sit amet consectetur. Id augue risus mattis interdum adipiscing vestibulum. At sit mi vitae proin adipiscing."/>
                            <Product image={product2} productName="Products" description="Lorem ipsum dolor sit amet consectetur. Id augue risus mattis interdum adipiscing vestibulum. At sit mi vitae proin adipiscing."/>
                            <Product image={product3} productName="Products" description="Lorem ipsum dolor sit amet consectetur. Id augue risus mattis interdum adipiscing vestibulum. At sit mi vitae proin adipiscing."/>
                            <Product image={product4} productName="Products" description="Lorem ipsum dolor sit amet consectetur. Id augue risus mattis interdum adipiscing vestibulum. At sit mi vitae proin adipiscing."/>
                            <Product image={product5} productName="Products" description="Lorem ipsum dolor sit amet consectetur. Id augue risus mattis interdum adipiscing vestibulum. At sit mi vitae proin adipiscing."/>

                            <Product image={product6} productName="Products" description="Lorem ipsum dolor sit amet consectetur. Id augue risus mattis interdum adipiscing vestibulum. At sit mi vitae proin adipiscing."/>
                        </div>
                        <div className="btn-container flex flex_content-center">
                            <Button className="btn_lg" text="See more" icon="icon-solar_alt-arrow-left-outlie"/>
                        </div>
                    </div>
                </div>
            </div> */}

            {/* <div className="team">
                <div className="swiper news-inner__slider full-width flex flex_column">
                    <div className="main-container slider-header_inner flex flex_space-between align_items-center">
                        <div className="team_title">
                            <h2 className="title news-inner__slider__header__heading">Meet <span className="news-inner__slider__header__heading_colored"> Our Team </span></h2>
                        </div>
                    </div>
                    <div className="swiper-wrapper news-inner__slider__items">
                        {
                            Array.from({length: numberOfSlides}).map((_, index) => (
                                <TeamMember 
                                    key={index}
                                    className="swiper-slide"
                                    avatar={ibragim}
                                    memberName="Ibrahim Mahmutov"
                                    position="CEO"
                                   
                                />
                            ))
                        }
                    </div>
                </div>
            </div> */}

            <div className="steps" id="steps">
                <div className="main-container">
                    <div className="steps-container">
                        <div className="steps_title">
                            <h3 className="title"> <span className="title_primary"> {__("Steps")} </span> {__("for receiving our product")} </h3>
                        </div>
                        <div className="steps-block flex flex_space-between">
                            <div className="step_items">
                                <Step stepNumber="1" heading="Sign up in Admin Panel" text="Begin by signing up on the admin panel. This will grant you access to the suite of tools and features essential for leveraging our product. Simply provide your details, choose a secure password, and you'll be on your way to experiencing everything our product has to offer."/>
                                <Step stepNumber="2" heading="Incorporate a Business" text="Next, incorporate your business right within our platform. By adding your company details, you establish your business's presence and unlock specific functionalities tailored for enterprises."/>
                                <Step stepNumber="3" heading="Receive Fiscal Module" text="Once your business is incorporated, you'll receive a fiscal module. This integral component ensures seamless financial operations and compliance within our platform. It's designed to assist with tax calculations, financial reporting, and other fiscal responsibilities, making your business operations smooth and efficient."/>
                                <Step stepNumber="4" heading="Receive a Cashdesk" text="After setting up the fiscal module, you'll be provided with a cashdesk. This essential tool facilitates all your transactional needs, from processing payments to handling receipts. Tailored for businesses of all sizes, our cashdesk ensures a smooth, secure, and efficient point-of-sale experience, allowing you to serve your customers with confidence and ease."/>
                            </div>
                            <div className="form flex">
                                <Form/>
                            </div>
                        </div>
                        
                        
                    </div>
                    
                </div>
            </div>

        </div>
    );
};

// EXPORTING PAGE
export default About;