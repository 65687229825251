import React, {useState} from "react";
import { NavLink, useNavigate } from "react-router-dom";
import logo from "../../../assets/svg/logo.svg"
import Button from "../Button";
import Languages from "../Languages";
import "./styles.sass";
import { useSelector } from "react-redux";
import { translate } from "../../../translations/translate";
import Modal from "react-modal";
import  exe from "../../../assets/images/exe.png"
// HEADER COMPONENT
const Header = () => {

    // LANGUAGE
    const language = useSelector(state => state.language.currentLanguage);
    const __ = (key) => translate(language, key);

    const [modal , setModal] = useState(false)
    if (modal === true){
        document.querySelector("html").style.overflow = "hidden"
    } else {
        document.querySelector("html").style.overflow = "scroll"
    }
    // CALLBACKS
    const goToPanel = function() {
        window.location.href = "http://management.epos.uz";
    };  
    const goToForm = function() {
        // const stepsBlock = document.querySelector("#steps");
        // stepsBlock.scrollIntoView({ behavior: 'smooth' });
        setModal(true)
    };
    const closeModal = function () {
        setModal(false);
    };
    // LAYOUT
    const [burgerIsOpen, setBurgerIsOpen] = useState(false)
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    window.addEventListener("resize", () => {
        setWindowWidth(window.innerWidth);
    })
    return (
        <>
            <div className={"modal"}>
                <Modal
                    className="delete-modal"
                    isOpen={modal}
                    onRequestClose={closeModal}
                >
                    <div className="modal-bottom">
                        <div className="component-modal-window">
                            <div className="modal-inner">
                                <div className="modal-title">
                                    <h1 className="modal-window-title">
                                        Download Cashdesk
                                    </h1>
                                </div>
                                <i
                                    className="icon-close modal-close"
                                    onClick={() => {
                                        setModal(false);
                                    }}
                                ></i>
                                <div className="modal-body">
                                    <div className="window-modal-text">
                                        <p className="window-text">
                                            Choose an appropriate format for your computer.
                                        </p>
                                    </div>
                                    <div className="modal-file bgw">
                                        <div className="file">
                                            <img className="file-image" src={exe} alt="logo" />
                                            <div className="file-text">
                                                <p className="text">
                                                    For x32-x64 bit
                                                </p>
                                                <p className="text-size">
                                                    138MB
                                                </p>
                                            </div>
                                        </div>

                                        <a
                                            href="https://github.com/epos-github/epos-demo/releases/download/v1.0.0/E-POS.DEMO.Setup.1.0.0.exe"
                                            download="cashdesk.exe"
                                            className="download-link"
                                        >
                                            <i className="icon-download"></i>
                                        </a>
                                    </div>
                                    {/*<div className="modal-file bgw">*/}
                                    {/*    <div className="file">*/}
                                    {/*        <img className="file-image" src={exe} alt="logo" />*/}
                                    {/*        <div className="file-text">*/}
                                    {/*            <p className="text">*/}
                                    {/*                For x64 bit*/}
                                    {/*            </p>*/}
                                    {/*            <p className="text-size">*/}
                                    {/*                139MB*/}
                                    {/*            </p>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}

                                    {/*    <a*/}
                                    {/*        href="https://github.com/epos-github/epos-app/releases/download/0.2.2/E-POS-Cashdesk-Setup-0.2.2.exe"*/}
                                    {/*        download="cashdesk.exe"*/}
                                    {/*        className="download-link"*/}
                                    {/*    >*/}
                                    {/*        <i className="icon-download"></i>*/}
                                    {/*    </a>*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>

            <header className={`header-container flex flex_content-center align_items-center ${burgerIsOpen ? "bordered" : ""}`}>
                <div className="main-container">
                    <div className="header flex flex_row flex_space-between align_items-center">
                        <NavLink to="/" className="header__logo">
                            <img src={logo} alt="E-POS Systems logo" />
                        </NavLink>
                        {windowWidth > 940 &&
                            <nav className="header__nav flex align_items-center">
                                <NavLink to="/" id="home" className="nav__link">{__("Home")}</NavLink>
                                <NavLink to="/about" id="about" className="nav__link">{__("About")}</NavLink>
                                <NavLink to="/our_product" id="product" className="nav__link">{__("Our Product")}</NavLink>
                                {/* <NavLink to="/news" id="news" className="nav__link">News</NavLink> */}
                                {/* <NavLink to="/news/:id" id="forDevelopers" className="nav__link desktop-only">For Developers</NavLink> */}
                                <NavLink to="/contacts" id="contacts" className="nav__link">{__("Contacts")}</NavLink>
                            </nav>
                        }
                        <div className="header__actions flex align_items-center">
                            {windowWidth > 940 &&
                                <>
                                    <Languages/>
                                    <Button className="btn_outline  header_margin" text="Sign up" onClick={goToPanel} />
                                    <Button text="Try Demo" onClick={goToForm} />
                                </>
                            }
                            {windowWidth <= 940 &&
                                <div className="burger-btn-container">
                                    <div className={`burger-btn ${burgerIsOpen ? "active" : ""}`}
                                         onClick={()=> setBurgerIsOpen(!burgerIsOpen)}
                                    >
                                        <span></span>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className={`mobile-menu ${burgerIsOpen ? "active" : ""}`}>
                    <div className="content-container">
                        <div className="top">
                            <nav className="mobile__nav">
                                <NavLink to="/" id="home" className="nav__link">{__("Home")}</NavLink>
                                <NavLink to="/about" id="about" className="nav__link">{__("About")}</NavLink>
                                <NavLink to="/our_product" id="product" className="nav__link">{__("Our Product")}</NavLink>
                                {/* <NavLink to="/news" id="news" className="nav__link">{("News")}</NavLink> */}
                                {/* <NavLink to="/news/:id" id="forDevelopers" className="nav__link desktop-only">{__("For Developers")}</NavLink> */}
                                <NavLink to="/contacts" id="contacts" className="nav__link">{__("Contacts")}</NavLink>
                            </nav>
                            <div className="lang-block">
                                <Languages/>
                            </div>
                        </div>
                        <div className="bottom">
                            <div className="btns-container">
                                <Button className="btn_outline btn_sm  header_margin" text="Sign up"/>
                                <Button onClick={goToForm} text="Try Demo"/>
                            </div>
                        </div>
                    </div>
                </div>

            </header>

        </>
    );
};  

// EXPORTING COMPONENT
export default Header;