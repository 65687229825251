import { NavLink, useLocation } from "react-router-dom";
import "./styles.sass";
import { useSelector } from "react-redux";
import { translate } from "../../../translations/translate";
// FORM COMPONENT
const Footer = () => {

    // LANGUAGE
    const language = useSelector(state => state.language.currentLanguage);
    const __ = (key) => translate(language, key);

    // USE LOCATION
    const location = useLocation();

    // LAYOUT
    return (
        <div className="flex flex_column footer">
            <div className="flex flex_space-around footer__wrapper">
                <div className="flex flex_column company-block">
                    <span className="footer__logo"></span>
                    <div className="finance-data flex flex_column">
                        <p className="company-name">{__("LLC")} “E-POS Systems”</p>
                        <p className="company-name">+998 71 200 01 73</p>
                    </div>
                </div>
                <div className="flex flex_column sections-block">
                    <h3 className="footer__heading_blue">{__("Sections")}</h3>
                    <div className="sections flex flex_column">
                        <NavLink to="/" className={`sections__item ${location.pathname === "/" ? "sections__item_active" : ""}`}>{__("Home")}</NavLink>
                        <NavLink to="/about" className={`sections__item ${location.pathname === "/about" ? "sections__item_active" : ""}`}>{__("About")}</NavLink>
                        <NavLink to="/our_product" className={`sections__item ${location.pathname === "/our_product" ? "sections__item_active" : ""}`}>{__("Our Product")}</NavLink>
                        {/* <NavLink to="/news" className={`sections__item ${location.pathname === "/news" ? "sections__item_active" : ""}`}>News</NavLink> */}
                        {/* <NavLink to="#" className={`sections__item ${location.pathname === "/dev_epos" ? "sections__item_active" : ""}`}>For developers</NavLink> */}
                        <NavLink to="/contacts" className={`sections__item ${location.pathname === "/contacts" ? "sections__item_active" : ""}`}>{__("Contacts")}</NavLink>
                    </div>
                </div>
                <div className="flex flex_column contacts-block">
                    <h3 className="footer__heading_blue">{__("Contacts")}</h3>
                    <div className="contacts flex flex_column">
                        <p className="sections__item"><i className="icon-solar_letter-bold footer__icon" />info@epos.uz</p>
                    </div>
                </div>
            </div>
            <p className="footer__text">{__("E-POS Systems 2023 All rights reserved.")}</p>
        </div>
    );
};

// EXPORTING COMPONENT
export default Footer;
