import { SWITCH_LANGUAGE } from "./actionTypes";

// INITIAL STATE
const initialState = {
    currentLanguage: "en",
};

// LANGUAGE REDUCER
export const languageReducer = (state = initialState, action) => {
    switch(action.type) {
        case SWITCH_LANGUAGE:
            return { ...state, currentLanguage: action.language };
        default:
            return state;
    };
};