import { useSelector } from "react-redux";
import "./styles.sass";
import { translate } from "../../../translations/translate";

// STEP COMPONENT
const Step = ({heading, text, stepNumber}) => {

    // LANGUAGE
    const language = useSelector(state => state.language.currentLanguage);
    const __ = (key) => translate(language, key);

    // LAYOUT
    return (
        <div className="step-box flex align_items-center">
            <div className="step-number">
                <p className="number">{stepNumber}</p>
            </div>
            <div className="shadow-box shadow-box_step _bra-24">
                <h3 className="shadow-box_step__heading">{__(heading)}</h3>
                <p className="shadow-box_step__text">{__(text)}</p>
            </div>
        </div>
       
    );
};

// EXPORTING COMPONENT
export default Step;