// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.shadow-box_product {
  cursor: pointer;
  max-width: 380px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  transition-duration: 0.3s;
}
.shadow-box_product .shadow-box_product__image {
  max-width: 332px;
  border-radius: 12px;
}
.shadow-box_product .shadow-box_product__heading {
  color: #1E1E1E;
}
.shadow-box_product .shadow-box_product__text_light {
  color: #818181;
}

.shadow-box_product:hover {
  box-shadow: 0px 4px 25px 0px rgba(56, 131, 146, 0.25);
}
.shadow-box_product:hover .shadow-box_product__heading {
  color: #388392;
}
.shadow-box_product:hover .shadow-box_product__text_light {
  color: #1E1E1E;
}`, "",{"version":3,"sources":["webpack://./src/components/UI/Product/styles.sass"],"names":[],"mappings":"AAEA;EACI,eAAA;EACA,gBAAA;EACA,aAAA;EACA,aAAA;EACA,sBAAA;EACA,aAAA;EACA,yBAAA;AADJ;AAEI;EACI,gBAAA;EACA,mBAAA;AAAR;AACI;EACI,cAAA;AACR;AAAI;EACI,cAAA;AAER;;AAAA;EACI,qDAAA;AAGJ;AAFI;EACI,cAAA;AAIR;AAHI;EACI,cAAA;AAKR","sourcesContent":["@import ../../../assets/styles/basic/_variables\n    \n.shadow-box_product\n    cursor: pointer\n    max-width: 380px\n    padding: 24px\n    display: flex\n    flex-direction: column\n    row-gap: 16px\n    transition-duration: 0.3s\n    .shadow-box_product__image\n        max-width: 332px\n        border-radius: 12px\n    .shadow-box_product__heading\n        color: map-get($default, \"black\")\n    .shadow-box_product__text_light\n        color: map-get($neutral, \"500\")\n    \n.shadow-box_product:hover\n    box-shadow: 0px 4px 25px 0px rgba(56, 131, 146, 0.25)\n    .shadow-box_product__heading\n        color: map-get($primary, \"500\")\n    .shadow-box_product__text_light\n        color: map-get($default, \"black\")"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
