import { useSelector } from "react-redux";
import "./styles.sass";
import { translate } from "../../../translations/translate";

// BUSINESS TYPE COMPONENT
const BusinessType = ({text, activeTab, onClick}) => {

    // LANGUAGE
    const language = useSelector(state => state.language.currentLanguage);
    const __ = (key) => translate(language, key);

    // LAYOUT
    return (
        <div className={`shadow-box shadow-box_business-type _bra-12 ${activeTab}`} onClick={onClick}>
            <p className="shadow-box_text">{__(text)}</p>
        </div>
    );
};

// EXPORTING COMPONENT
export default BusinessType;