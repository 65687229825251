// core version + navigation, pagination modules:
import Swiper from 'swiper';
import { Navigation, Pagination, Autoplay } from 'swiper';

// Import Swiper styles
import 'swiper/swiper-bundle.min.css';

// INITIALIZING SWIPER
export function initializeSwiper() {

    Swiper.use([Navigation, Pagination, Autoplay]);

    const swiper = new Swiper('.swiper', {
        // // Optional parameters
        direction: 'horizontal',
        loop: true,

        slidesPerView: "auto", // Set to 'auto' to make slides fill the screen width
        centeredSlides: true,  // Center the active slide
        spaceBetween: 30,       // No space between slides
        speed: 1000, 
        slideToClickedSlide: true,
        

        autoplay: {
            delay: 2500,
            disableOnInteraction: false, // Allow interaction (clicking next/prev buttons) without stopping autoplay
        },
        // If we need pagination
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },

        // Navigation arrows
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    });


    return swiper;
}
