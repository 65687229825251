// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.shadow-box_company-number {
  max-width: 312px;
  width: 100%;
  height: 146px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 0;
}
@media screen and (max-width: 400px) {
  .shadow-box_company-number {
    height: inherit;
    padding: 8px;
  }
}
@media screen and (max-width: 620px) {
  .shadow-box_company-number .company-heading {
    font-size: 24px;
    line-height: normal;
    font-weight: 600;
  }
}
@media screen and (max-width: 620px) {
  .shadow-box_company-number .company-subtitle {
    font-size: 12px;
    font-weight: 400;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/UI/CompanyNumber/styles.sass"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,WAAA;EACA,aAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,eAAA;AACJ;AAAI;EARJ;IASQ,eAAA;IACA,YAAA;EAGN;AACF;AAFQ;EADJ;IAEQ,eAAA;IACA,mBAAA;IACA,gBAAA;EAKV;AACF;AAJQ;EADJ;IAEQ,eAAA;IACA,gBAAA;EAOV;AACF","sourcesContent":[".shadow-box_company-number\n    max-width: 312px\n    width: 100%\n    height: 146px\n    display: flex\n    flex-direction: column\n    align-items: center\n    padding: 24px 0\n    @media screen and (max-width: 400px)\n        height: inherit\n        padding: 8px\n    .company-heading\n        @media screen and (max-width: 620px)\n            font-size: 24px\n            line-height: normal\n            font-weight: 600\n    .company-subtitle\n        @media screen and (max-width: 620px)\n            font-size: 12px\n            font-weight: 400"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
