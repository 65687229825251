import { useState } from "react";
import Button from "../../UI/Button";
import Checkbox from "../../UI/Checkbox";
import "./styles.sass";
import {IMaskInput} from "react-imask";
import axios from "axios";
import {translate} from "../../../translations/translate";
import {useSelector} from "react-redux";

// FORM COMPONENT
const Form = () => {

    // TOKEN VARIABLE
    const token = "6640927373:AAGp9rCgMIe6c4tdeHy_LLbh39F3dq-J2Y0";

    // CHAT ID
    const chatId = "5649993408";

    // USE STATE
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [message, setMessage] = useState("");
    const [isChecked, setIsChecked] = useState(false);
    // HANDLE CHECKBOX CHANGE
    const handleCheckbox = (event) => {
        setIsChecked(previous => !previous);
    };
    // LANGUAGE
    const language = useSelector(state => state.language.currentLanguage);
    const __ = (key) => translate(language, key);
    // ON FORM SUBMIT
    const submitForm = function(event) {
        event.preventDefault();
        const textToSend = `Name: ${name}\nPhone: ${phone}\nMessage: ${message}\nFrom E-POS website`;
        const encodedText = encodeURIComponent(textToSend);
        axios.get(`https://api.telegram.org/bot${token}/sendMessage?chat_id=${chatId}&text=${encodedText}`)
        .then(response => {
            if(response.data?.ok) {
                setName("");
                setPhone("");
                setMessage("");
            }
        })
    };
    // LAYOUT
    return (
        <form className="form-box flex flex_column flex_space-between" onSubmit={submitForm} id="form1">
            <div className="input-row">
                <label className="label" htmlFor="name"/>
                <input 
                    className="input" 
                    type="text" 
                    id="name" 
                    name="name"
                    placeholder={__("Name")}
                    value={name}
                    required={true}
                    onChange={(e) => setName(e.target.value)}
                />
            </div>
            <div className="input-row">
                <label className="label" htmlFor="phone"/>
                <IMaskInput 
                    mask="+998(00)000-00-00" 
                    className="input"
                    minLength={17}
                    maxLength={17}
                    type="tel" 
                    id="phone" 
                    name="phone" 
                    placeholder={__("Phone number")}
                    value={phone}
                    required={true}
                    onChange={(e) => setPhone(e.target.value)}
                />
            </div>
            <div className="input-row">
                <label className="label" htmlFor="text"/>
                <textarea  className="textarea" type="text" id="text" name="text" placeholder={__("Message" )}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                />
            </div>
            <Checkbox 
                onClick={handleCheckbox} 
                htmlFor="check" 
                id="check" 
                text={__("I am informed about the privacy policy and consent to the processing of my personal data.")}
            />
            <Button text="Submit" type={"submit"} isDisabled={!isChecked} />
        </form>
    );
};

// EXPORTING COMPONENT
export default Form;