import "./styles.sass";




// PARTNER COMPONENT
const Partner = ({ backgroundColor, className, firstImage, secondImage, boxBackgroundColor}) => {

    // LAYOUT
    return (
        <div  className={`partners-block flex flex_space-between ${className} `} >
            <div  className="partners-box">
                <div className={`partner-box_no-flip shadow-box shadow-box_business-partner _bra-16 shadow-box_${backgroundColor} ${boxBackgroundColor}`}>
                    <img src={firstImage} alt="logo" className="img-logo" />
                </div>
            </div>
        </div>
    );
};

// EXPORTING COMPONENT
export default Partner;