// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.shadow-box_team-member {
  display: flex;
  max-width: 380px;
  box-shadow: 0px 4px 25px 0px rgba(38, 84, 93, 0.25);
}
.shadow-box_team-member .shadow-box_team-member__image {
  width: 90px;
  height: 90px;
  border-radius: 24px;
}
.shadow-box_team-member .shadow-box_team-member__block {
  margin-left: 16px;
}
.shadow-box_team-member .shadow-box_team-member__block .shadow-box_team-member__heading {
  color: #1E1E1E;
  margin-top: 12px;
}
.shadow-box_team-member .shadow-box_team-member__block .shadow-box_team-member__text {
  color: #818181;
}`, "",{"version":3,"sources":["webpack://./src/components/UI/TeamMember/styles.sass"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,gBAAA;EACA,mDAAA;AADJ;AAEI;EACI,WAAA;EACA,YAAA;EACA,mBAAA;AAAR;AACI;EACI,iBAAA;AACR;AAAQ;EACI,cAAA;EACA,gBAAA;AAEZ;AADQ;EACI,cAAA;AAGZ","sourcesContent":["@import ../../../assets/styles/basic/_variables\n    \n.shadow-box_team-member\n    display: flex\n    max-width: 380px\n    box-shadow: map-get($cardBoxShadow, \"shadow\")\n    .shadow-box_team-member__image\n        width: 90px\n        height: 90px\n        border-radius: 24px\n    .shadow-box_team-member__block\n        margin-left: 16px\n        .shadow-box_team-member__heading\n            color: map-get($default, \"black\")\n            margin-top: 12px\n        .shadow-box_team-member__text\n            color: map-get($neutral, \"500\")\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
