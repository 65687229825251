// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagination {
  align-self: center;
  max-width: 626px;
  column-gap: 52px;
}
.pagination .pagination__buttons {
  column-gap: 12px;
}

@media (max-width: 670px) {
  .pagination {
    column-gap: 26px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Functional/Pagination/styles.sass"],"names":[],"mappings":"AAEA;EACI,kBAAA;EACA,gBAAA;EACA,gBAAA;AADJ;AAEI;EACI,gBAAA;AAAR;;AACA;EACI;IACI,gBAAA;EAEN;AACF","sourcesContent":["@import ../../../assets/styles/basic/_variables\n    \n.pagination\n    align-self: center\n    max-width: 626px\n    column-gap: 52px\n    .pagination__buttons\n        column-gap: 12px\n@media (max-width: 670px)\n    .pagination\n        column-gap: 26px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
