import { applyMiddleware, combineReducers, createStore } from "redux";
import { languageReducer } from "./language/reducer";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";

// COMBINATION OF REDUCER
const reducer = combineReducers({
    language: languageReducer,
});

// PERSISTED REDUCER CONFIGURATION
const persistConfig = {
    key: "root",
    storage,
    whitelist: ["language"],
};

// PERSISTED REDUCER
const persistedReducer = persistReducer(persistConfig, reducer);

// EXPORT
export const store = createStore(persistedReducer, applyMiddleware(thunk));
export const persistor = persistStore(store);