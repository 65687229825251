// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.shadow-box_business-type {
  display: inline-flex;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  cursor: pointer;
  margin-top: 24px;
  transition: all 1s ease;
}
@media (max-width: 400px) {
  .shadow-box_business-type {
    margin-top: 12px;
  }
}
.shadow-box_business-type:hover {
  box-shadow: 0px 4px 25px 0px rgba(38, 84, 93, 0.25);
}
.shadow-box_business-type:hover .shadow-box_text {
  color: #388392;
}
.shadow-box_business-type .shadow-box_text {
  color: #818181;
  font-size: 24px;
}
@media screen and (max-width: 768px) {
  .shadow-box_business-type .shadow-box_text {
    font-size: 16px;
    font-weight: 500;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/UI/BusinessType/styles.sass"],"names":[],"mappings":"AAEA;EACI,oBAAA;EACA,YAAA;EACA,cAAA;EACA,gBAAA;EACA,uBAAA;EACA,mBAAA;EACA,kBAAA;EACA,eAAA;EACA,gBAAA;EAIA,uBAAA;AAJJ;AACI;EAVJ;IAWQ,gBAAA;EAEN;AACF;AAAI;EACI,mDAAA;AAER;AADQ;EACI,cAAA;AAGZ;AAFI;EACI,cAAA;EACA,eAAA;AAIR;AAHQ;EAHJ;IAIQ,eAAA;IACA,gBAAA;EAMV;AACF","sourcesContent":["@import ../../../assets/styles/basic/_variables\n    \n.shadow-box_business-type\n    display: inline-flex\n    flex-grow: 0\n    flex-shrink: 1\n    flex-basis: auto\n    justify-content: center\n    align-items: center\n    padding: 12px 16px\n    cursor: pointer\n    margin-top: 24px\n    @media (max-width: 400px)\n        margin-top: 12px\n\n    transition: all 1s ease\n    &:hover\n        box-shadow: map-get($cardBoxShadow, \"shadow\")\n        .shadow-box_text\n            color: map-get($primary, \"500\")\n    .shadow-box_text\n        color: map-get($neutral, \"500\")\n        font-size: 24px\n        @media screen and (max-width: 768px)\n            font-size: 16px\n            font-weight: 500"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
